import moment from 'moment/moment';
import { IInvoice } from '../@types/invoice';

export const generateJSONtoCSV = (selected: IInvoice[]) => selected?.map((invoice) => ({
    id: invoice?.id,
    invoiceNumber: invoice?.invoiceNumber,
    invoiceDate: moment(invoice.invoiceDate).format('YYYY-MM-DD'),
    dueDate: moment(invoice.dueDate).format('YYYY-MM-DD'),
    recipient: invoice?.recipient?.name ?? invoice?.externalRecipient?.name ?? `${invoice?.recipient?.firstName} ${invoice?.recipient?.lastName}`,
    payer: invoice?.externalPayer?.name ?? invoice?.payer?.firstName ? `${invoice?.payer?.firstName} ${invoice?.payer?.lastName}` : `${invoice?.createdUser?.firstName} ${invoice?.createdUser?.lastName}`,
    paymentMethod: invoice.paymentMethod,
    wallet: invoice.cryptoCurrencyAddress?.address,
    chain: invoice.cryptoCurrencyAddress?.chain,
    currency: invoice.cryptoCurrencyAddress?.currency,
    tax: invoice?.tax || '',
    discount: invoice?.discount || '',
    total: invoice?.total || '',
    status: invoice.status,
    txid: invoice?.transactionHash || '',
    paidAt: moment(invoice.paidAt).format('yyyy-MM-DD HH:mm'),
    createdDatetime: moment(invoice.createdDatetime).format('yyyy-MM-DD HH:mm'),
    updatedDatetime: moment(invoice.updatedDatetime).format('yyyy-MM-DD HH:mm'),
  }));

export const generateExamplePaymentsCSV = () => ([{
    invoiceNumber: '',
    currency: '',
    address: '',
    total: '',
}]);

export const generateExampleRecipientCSV = () => ([{
    name: '',
    currency: '',
    address: '',
    addressName: '',
}]);