import * as XLSX from 'xlsx';
// types
import { ParseCallback, ValidateItemFunction, AvailableCurrencyFunction, ListItem } from '../sections/data-export';

// Parsing of Excel (.xls, .xlsx) files
export const parseRecipientsExcelFile = (
  file: File,
  callback: ParseCallback,
  isValidateItem: ValidateItemFunction,
  isAvailableCurrency: AvailableCurrencyFunction
) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    const target = e.target as FileReader;

    const data = new Uint8Array(target.result as ArrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });

    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    const headers = sheetData[0];
    const dataRows = sheetData.slice(1);

    const listItems = dataRows.map((row, index) => {
      const item: Record<string, any> = {};
      (headers as string[]).forEach((header: string, i: number) => {
        item[header] = (row as any[])[i];
      });

      return {
        id: item?.invoiceNumber ?? index + 1,
        name: item?.name?.length > 0 ? item.name : `Recipient-${index + 1}`,
        address: item.address,
        addressName: item.addressName,
        currency: item.currency,
        isAddressValid: isValidateItem(item),
        isAvailableCurrency: isAvailableCurrency(item.currency),
        isDuplicate: dataRows.filter(
          (e: any) =>
            (headers as string[]).indexOf('name') !== -1 && e[(headers as string[]).indexOf('name')] === item?.name &&
            (headers as string[]).indexOf('address') !== -1 && e[(headers as string[]).indexOf('address')] === item?.address &&
            (headers as string[]).indexOf('currency') !== -1 && e[(headers as string[]).indexOf('currency')] === item?.currency
        ).length > 1,
      };
    });

    callback(listItems);
  };

  reader.readAsArrayBuffer(file);
};

export const parseInvoiceExcelFile = (
  file: File,
  callback: ParseCallback,
  isValidateItem: ValidateItemFunction,
  validateCurrency: (currency: string) => boolean,
  //isAvailableCurrency: AvailableCurrencyFunction
) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    const target = e.target as FileReader;
    const data = new Uint8Array(target.result as ArrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });

    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    const headers = sheetData[0] as string[];
    const dataRows = sheetData.slice(1);

    const listItems: ListItem[] = dataRows.map((row, index) => {
      const item: Record<string, any> = {};
      (headers as string[]).forEach((header: string, i: number) => {
        item[header] = (row as any[])[i];
      });

      return {
        id: item?.invoiceNumber,
        name: item?.name?.length > 0 ? item.name : `Recipient-${index + 1}`,
        address: item.address,
        addressName: item.addressName ?? '',
        amount: item.amount,
        currency: item.currency,
        isAddressValid: isValidateItem(item),
        isCurrencyValid: validateCurrency(item.currency),
        isTotalValid: !isNaN(Number(item?.amount)),
      };
    });

    callback(listItems);
  };

  reader.readAsArrayBuffer(file);
};

export const parseXLSXFileMassPayments = async (
  file: File,
  setList: (items: any[]) => void,
  isValidateItem: (item: any) => boolean,
  validateCurrency: (currency: string) => boolean,
  listRecipients: any[]
) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    const target = e.target as FileReader;
    const data = new Uint8Array(target.result as ArrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });

    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    const headers = sheetData[0] as string[];
    const dataRows = sheetData.slice(1);

    const listItems = dataRows.map((row, index) => {
      const item: Record<string, any> = {};
      headers.forEach((header, i) => {
        item[header] = (row as any[])[i];
      });

      return {
        id: item?.invoiceNumber,
        name: item?.name?.length > 0 ? item.name : `Recipient-${index + 1}`,
        address: item.address,
        currency: item.currency,
        amount: item?.amount,
        isAddressValid: isValidateItem(item),
        isCurrencyValid: validateCurrency(item.currency),
        isTotalValid: !isNaN(Number(item?.amount)),
        recipient: listRecipients
          ?.map((recipient) => ({
            ...recipient,
            addresses: recipient?.addresses
              ?.find((address: any) => address?.address === item.address && item.currency.includes(address?.currency) && item.currency.includes(address?.chain))
          }))
          ?.find(item => item?.addresses)
      };
    });

    setList(listItems);
  };

  reader.readAsArrayBuffer(file);
};
