import Papa from 'papaparse';
// types
import { ParseCallback, ValidateItemFunction, AvailableCurrencyFunction, ListItem } from '../sections/data-export';

export const parseRecipientsCSVFile = (
  file: File,
  callback: ParseCallback,
  isValidateItem: ValidateItemFunction,
  isAvailableCurrency: AvailableCurrencyFunction
) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: function (results) {
      const listItems = results.data.map((item: any, index: number) => ({
        id: item?.invoiceNumber,
        name: item?.name?.length > 0 ? item.name : `Recipient-${index + 1}`,
        address: item.address,
        addressName: item.addressName,
        currency: item.currency,
        isAddressValid: isValidateItem(item),
        isAvailableCurrency: isAvailableCurrency(item.currency),
        isDuplicate: results.data.filter(
          (e: any) => e?.name === item?.name && e?.address === item?.address && e?.currency === item?.currency
        ).length > 1
      }));
      callback(listItems);
    },
  });
};

export const parseInvoiceCSVFile = (
  file: File,
  callback: ParseCallback,
  isValidateItem: ValidateItemFunction,
  validateCurrency: (currency: string) => boolean,
) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: function (results) {
      const listItems: ListItem[] = results.data.map((item: any, index: number) => ({
        id: item?.invoiceNumber,
        name: item?.name?.length > 0 ? item.name : `Recipient-${index + 1}`,
        address: item.address,
        addressName: item.addressName ?? '',
        amount: item.amount,
        currency: item.currency,
        isAddressValid: isValidateItem(item),
        isCurrencyValid: validateCurrency(item.currency),
        isTotalValid: !isNaN(Number(item?.amount)),
      }));
      callback(listItems);
    },
  });
};

export const parseCSVFileMassPayments = (
  file: File,
  setList: (items: any[]) => void,
  isValidateItem: (item: any) => boolean,
  validateCurrency: (currency: string) => boolean,
  listRecipients: any[]
) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: function (results) {
      const listItems = results.data?.map((item: any, index: number) => ({
        id: item?.invoiceNumber,
        name: item?.name?.length > 0 ? item.name : `Recipient-${index + 1}`,
        address: item.address,
        currency: item.currency,
        amount: item?.amount,
        isAddressValid: isValidateItem(item),
        isCurrencyValid: validateCurrency(item.currency),
        isTotalValid: !isNaN(Number(item?.amount)),
        recipient: listRecipients
          ?.map((recipient) => ({
            ...recipient,
            addresses: recipient?.addresses
              ?.find((address: any) => address?.address === item.address && item.currency.includes(address?.currency) && item.currency.includes(address?.chain))
          }))
          ?.find(item => item?.addresses)
      }));

      setList(listItems);
    }
  });
};