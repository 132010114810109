import React from "react";

import { SvgIcon } from '@mui/material';

const DashIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#026DC1",
      ...props.style,
    }}
    viewBox="0 0 73 73"
  >
    <path
      d="M66.9996 36.5C66.9996 19.6553 53.3443 6 36.4996 6C19.6549 6 5.99963 19.6553 5.99963 36.5C5.99963 53.3447 19.6549 67 36.4996 67C53.3443 67 66.9996 53.3447 66.9996 36.5Z"
      fill="#026DC1"
    />
    <path
      d="M70 36.5C70 17.9985 55.0015 3 36.5 3C17.9985 3 3 17.9985 3 36.5C3 55.0015 17.9985 70 36.5 70C55.0015 70 70 55.0015 70 36.5Z"
      stroke="white"
      strokeWidth="6"
    />
    <path
      d="M19.3951 48.1317C19.9586 46.4191 20.4568 44.8187 21.0204 43.1948C21.0757 43.1107 21.1495 43.0401 21.2361 42.9886C21.3226 42.9371 21.4198 42.9059 21.5202 42.8975H42.3277C42.423 42.9133 42.5206 42.9093 42.6144 42.8857C42.7081 42.8622 42.796 42.8195 42.8726 42.7605C43.0337 42.6364 43.1399 42.4543 43.1684 42.2529C44.1663 39.1221 45.2017 35.9819 46.237 32.8495C46.2915 32.6767 46.3179 32.5225 46.4098 32.2641H24.5343C25.0978 30.5157 25.6241 28.9215 26.1861 27.2697C26.2303 27.1963 26.2911 27.1344 26.3635 27.0887C26.436 27.043 26.5181 27.0149 26.6033 27.0066H28.2552C35.655 27.0066 43.0719 27.0937 50.4888 27.0066C51.1056 26.969 51.7217 27.0929 52.2761 27.3659C52.8306 27.6389 53.3043 28.0516 53.6508 28.5634C54.0136 29.0914 54.2453 29.6984 54.3265 30.3339C54.4077 30.9694 54.3362 31.6151 54.1178 32.2174C53.4188 34.7815 52.4925 37.2694 51.6844 39.776C51.4026 40.6307 51.1302 41.5243 50.85 42.3495C50.4074 43.7252 49.6245 44.9668 48.5739 45.9591C47.5233 46.9514 46.2391 47.6623 44.8405 48.0258C44.562 48.1149 44.27 48.1539 43.978 48.141H20.0131C19.8059 48.158 19.5976 48.158 19.3904 48.141L19.3951 48.1317Z"
      fill="white"
    />
    <path
      d="M33.2491 35.0693C32.6948 36.7212 32.1593 38.2874 31.6066 39.7944C31.6066 39.919 31.3528 40.0062 31.2158 40.0062H18.7874C18.7057 39.9959 18.6277 39.966 18.5601 39.919C19.0863 38.3823 19.5954 36.8737 20.1481 35.3667C20.1895 35.2857 20.2502 35.2162 20.3249 35.1644C20.3996 35.1125 20.4859 35.0799 20.5762 35.0693H33.2491Z"
      fill="white"
    />
  </SvgIcon>
);

export default DashIcon;
