import React from "react";

import { SvgIcon } from '@mui/material';

const DogeIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#BCA131",
      ...props.style,
    }}
    viewBox="0 0 73 73"
  >
    <path
      d="M66.9996 36.5C66.9996 19.6553 53.3443 6 36.4996 6C19.6549 6 5.99963 19.6553 5.99963 36.5C5.99963 53.3447 19.6549 67 36.4996 67C53.3443 67 66.9996 53.3447 66.9996 36.5Z"
      fill="#BCA131"
    />
    <path
      d="M70 36.5C70 17.9985 55.0015 3 36.5 3C17.9985 3 3 17.9985 3 36.5C3 55.0015 17.9985 70 36.5 70C55.0015 70 70 55.0015 70 36.5Z"
      stroke="white"
      strokeWidth="6"
    />
    <path
      d="M38.7658 22.5H24V27.3911H27.8593V34.0545H24V38.9455H27.8593V45.6089H24V50.5H38.506C46.9195 50.5 52 44.3175 52 36.1462C52 27.8719 46.7745 22.5 38.7658 22.5ZM38.2631 45.6089H33.8067V38.9455H37.666V34.0545H33.8067V27.3911H38.3002C43.6978 27.3911 45.867 31.5918 45.867 36.1634C45.867 41.6178 43.5359 45.6089 38.2631 45.6089Z"
      fill="white"
    />
  </SvgIcon>
);

export default DogeIcon;
