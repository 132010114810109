import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';
//

// ----------------------------------------------------------------------

function BadRequestIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other}  sx={{ minWidth: { md: '423px' }, height: { md: 'auto' } }}>
      <svg width="100%" height="100%" viewBox="0 0 423 282" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1375_3904" maskUnits="userSpaceOnUse" x="206" y="44" width="98"
              height="105">
          <path d="M206.096 50.0282L234.523 138.474L303.682 148.119L297.227 44.2831L206.096 50.0282Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1375_3904)">
          <path d="M297.194 44.2622L130.466 54.822L141.026 221.55L307.754 210.991L297.194 44.2622Z" fill="#68CA87" />
          <path
            d="M140.447 212.655C140.447 212.655 206.793 198.404 246.235 158.578C285.678 118.753 298.301 61.3201 298.301 61.3201L307.739 211.015L141.007 221.526L140.447 212.655Z"
            fill="#49A365" />
          <path d="M148.428 179.525L290.087 170.594L241.287 125.214L203.147 159.083L176.341 143.153L148.428 179.525Z"
                fill="#FFD55F" />
          <path
            d="M258.426 102.079C266.404 102.079 272.872 95.6116 272.872 87.6336C272.872 79.6556 266.404 73.1881 258.426 73.1881C250.448 73.1881 243.981 79.6556 243.981 87.6336C243.981 95.6116 250.448 102.079 258.426 102.079Z"
            fill="#FFD55F" />
          <path d="M297.227 44.2831L135.618 59.3053L134.553 119.156L130.496 54.7947L297.227 44.2831Z" fill="#E1F4E7" />
        </g>
        <mask id="mask1_1375_3904" maskUnits="userSpaceOnUse" x="135" y="143" width="168"
              height="78">
          <path d="M135.393 182.014L233.949 143.392L302.365 157.369L302.456 220.396H135.393V182.014Z" fill="white" />
        </mask>
        <g mask="url(#mask1_1375_3904)">
          <path d="M302.456 53.3333H135.393V220.396H302.456V53.3333Z" fill="#68CA87" />
          <path
            d="M135.393 211.507C135.393 211.507 202.503 201.458 244.374 164.194C286.245 126.93 302.456 70.4041 302.456 70.4041V220.396H135.393V211.507Z"
            fill="#49A365" />
          <path d="M145.441 178.945H287.382L241.534 130.584L201.339 161.986L175.589 144.401L145.441 178.945Z"
                fill="#FFD55F" />
        </g>
        <mask id="mask2_1375_3904" maskUnits="userSpaceOnUse" x="126" y="55" width="99"
              height="129">
          <path d="M126.105 55.2572H201.856L224.662 145.316L126.105 183.938V55.2572Z" fill="white" />
        </mask>
        <g mask="url(#mask2_1375_3904)">
          <path d="M293.168 55.2572H126.105V222.32H293.168V55.2572Z" fill="#68CA87" />
          <path
            d="M126.105 213.431C126.105 213.431 193.216 203.382 235.086 166.118C276.957 128.854 293.168 72.3281 293.168 72.3281V222.32H126.105V213.431Z"
            fill="#49A365" />
          <path d="M136.155 180.868H278.095L232.247 132.508L192.051 163.91L166.301 146.325L136.155 180.868Z"
                fill="#FFD55F" />
          <path d="M293.168 55.2572L130.934 60.0808L126.105 119.746V55.2572H293.168Z" fill="#E1F4E7" />
        </g>
        <path d="M64.7881 168.119L31.4077 189.338L68.3074 236.576L94.8676 208.641L64.7881 168.119Z" fill="#FFD55F" />
        <path
          d="M106.408 196.503L135.874 165.289L158.887 146.43L176.022 109.737L167.01 109.359L156.773 122.965L148.542 128.662L160.529 64.0274L152.664 64.6697L142.237 94.912L150.444 48.6133L142.311 50.8319L129.576 89.7874L136.279 53.3001L127.193 57.2354L124.169 67.2478L117.02 69.4968L99.8308 122.024L96.212 148.144L64.7881 168.119L94.8676 208.641L106.408 196.503Z"
          fill="#FFD55F" />
        <path d="M124.169 67.2479L116.428 94.3163L120.856 68.2899L124.169 67.2479Z" fill="#FF5631" />
        <path d="M144.808 87.4524L140.211 101L150.444 48.6133L144.808 87.4524Z" fill="#FF5631" />
        <path d="M136.279 53.3001L129.576 89.7874L126.906 96.0993L128.753 80.0753L134.392 54.1172L136.279 53.3001Z"
              fill="#FF5631" />
        <path
          d="M160.529 64.0274L151.232 100.238L146.236 129.605L157.943 124.815L158.692 120.414L156.773 122.965L149.644 127.034L160.529 64.0274Z"
          fill="#FF5631" />
        <path d="M112.55 98.1952L115.535 97.1356L117.651 98.2791L112.55 98.1952Z" fill="#FF5631" />
        <path d="M123.563 99.479L126.71 99.1117L128.518 100.699L123.563 99.479Z" fill="#FF5631" />
        <path d="M137.063 100.727L140.21 100.36L142.018 101.947L137.063 100.727Z" fill="#FF5631" />
        <path d="M158.887 146.43L129.714 167.542L62.241 227.771L68.3075 236.576L136.833 164.503L158.887 146.43Z"
              fill="#FF5631" />
        <path d="M124.169 67.2479L118.475 70.2378L115.255 74.8908L117.02 69.4968L124.169 67.2479Z" fill="#FFAC5F" />
        <path d="M125.117 64.1084L127.962 58.5326L131.986 55.1592L127.192 57.2354L125.117 64.1084Z" fill="#FFAC5F" />
        <path d="M137.293 66.1798L144.397 51.8203L150.444 48.6133L142.31 50.8319L137.293 66.1798Z" fill="#FFAC5F" />
        <path d="M149.763 73.0817L152.664 64.6697L160.529 64.0274L154.104 65.9048L149.763 73.0817Z" fill="#FFAC5F" />
        <path d="M99.8308 122.024L97.4867 148.581L35.1421 194.119L31.4077 189.338L96.212 148.144L99.8308 122.024Z"
              fill="#FFAC5F" />
        <path
          d="M391.401 225.839L333.507 168.884L309.157 158.988L292.92 141.291L268.028 120.959L270.44 115.171L279.776 115.54L301.556 127.644L304.497 122.765L301.457 113.697L299.956 91.0303L299.287 80.9156L319.772 104.088L324.533 106.901L349.349 142.592L391.592 168.59L391.401 225.839Z"
          fill="#FFD55F" />
        <path d="M270.44 115.171L279.119 121.777L276.342 125.874L270.416 122.91L268.028 120.959L270.44 115.171Z"
              fill="#FFAC5F" />
        <path d="M319.772 104.088L331.302 116.636L324.533 106.901L319.772 104.088Z" fill="#FFAC5F" />
        <path d="M301.556 127.644L307.204 129.877L304.22 123.224L301.556 127.644Z" fill="#FF5631" />
        <path d="M333.507 168.884L391.401 209.395V225.839L333.507 168.884Z" fill="#FF5631" />
        <path d="M300.797 82.6232L303.682 120.336L301.457 113.697L299.287 80.9156L300.797 82.6232Z" fill="#FF5631" />
      </svg>
    </Box>
  );
}

export default memo(BadRequestIllustration);
