import React from "react";

import { SvgIcon } from '@mui/material';

const SnodeCoinIcon = (props: any) => (
  <SvgIcon
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    viewBox="0 0 320 320"
  >
    <circle fill={props.fill || "#2d598a"} cx="160" cy="160" r="160"/>
    <path fill="white" d="M158.65 227.05L158.65 227.05Q143.35 227.05 128.95 223.27L128.95 223.27L128.95 223.27Q114.55 219.49 105.55 213.19L105.55 213.19L117.25 186.91L117.25 186.91Q125.71 192.49 136.87 195.91L136.87 195.91L136.87 195.91Q148.03 199.33 158.83 199.33L158.83 199.33L158.83 199.33Q179.35 199.33 179.35 189.07L179.35 189.07L179.35 189.07Q179.35 183.67 173.50 181.06L173.50 181.06L173.50 181.06Q167.65 178.45 154.69 175.57L154.69 175.57L154.69 175.57Q140.47 172.51 130.93 169.00L130.93 169.00L130.93 169.00Q121.39 165.49 114.55 157.75L114.55 157.75L114.55 157.75Q107.71 150.01 107.71 136.87L107.71 136.87L107.71 136.87Q107.71 125.35 114.01 116.08L114.01 116.08L114.01 116.08Q120.31 106.81 132.82 101.41L132.82 101.41L132.82 101.41Q145.33 96.01 163.51 96.01L163.51 96.01L163.51 96.01Q175.93 96.01 187.99 98.80L187.99 98.80L187.99 98.80Q200.05 101.59 209.23 106.99L209.23 106.99L198.25 133.45L198.25 133.45Q180.25 123.73 163.33 123.73L163.33 123.73L163.33 123.73Q152.71 123.73 147.85 126.88L147.85 126.88L147.85 126.88Q142.99 130.03 142.99 135.07L142.99 135.07L142.99 135.07Q142.99 140.11 148.75 142.63L148.75 142.63L148.75 142.63Q154.51 145.15 167.29 147.85L167.29 147.85L167.29 147.85Q181.69 150.91 191.14 154.42L191.14 154.42L191.14 154.42Q200.59 157.93 207.52 165.58L207.52 165.58L207.52 165.58Q214.45 173.23 214.45 186.37L214.45 186.37L214.45 186.37Q214.45 197.71 208.15 206.89L208.15 206.89L208.15 206.89Q201.85 216.07 189.25 221.56L189.25 221.56L189.25 221.56Q176.65 227.05 158.65 227.05L158.65 227.05Z"/>
  </SvgIcon>
);

export default SnodeCoinIcon;
