import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function MaintenanceIllustration({ ...other }: BoxProps) {

  return (
    <Box {...other} sx={{ minWidth: { md: '377px' }, height: { md: 'auto' } }}>
      <svg width="100%" height="100%" viewBox="0 0 377 251" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57.6162 148.561H56.5242V149.651H57.6162V148.561Z" fill="#FF5631" />
        <path d="M61.9336 148.561H60.8416V149.651H61.9336V148.561Z" fill="#FF5631" />
        <path d="M66.2515 148.561H65.1594V149.651H66.2515V148.561Z" fill="#FF5631" />
        <path d="M70.5693 148.561H69.4773V149.651H70.5693V148.561Z" fill="#FF5631" />
        <path d="M74.8872 148.561H73.7952V149.651H74.8872V148.561Z" fill="#FF5631" />
        <path d="M79.2046 148.561H78.1125V149.651H79.2046V148.561Z" fill="#FF5631" />
        <path d="M83.5225 148.561H82.4304V149.651H83.5225V148.561Z" fill="#FF5631" />
        <path d="M87.8403 148.561H86.7483V149.651H87.8403V148.561Z" fill="#FF5631" />
        <path d="M92.1575 148.561H91.0654V149.651H92.1575V148.561Z" fill="#FF5631" />
        <path d="M96.4756 148.561H95.3835V149.651H96.4756V148.561Z" fill="#FF5631" />
        <path d="M100.793 148.561H99.7014V149.651H100.793V148.561Z" fill="#FF5631" />
        <path d="M105.111 148.561H104.019V149.651H105.111V148.561Z" fill="#FF5631" />
        <path d="M109.428 148.561H108.336V149.651H109.428V148.561Z" fill="#FF5631" />
        <path d="M113.747 148.561H112.655V149.651H113.747V148.561Z" fill="#FF5631" />
        <path
          d="M372.218 148.013L329.914 118.47L276.875 111.49L258.227 131.724L259.74 152.933L266.868 145.681L267.05 133.413L278.138 127.017L284.607 128.519L282.776 141.532L273.02 146.653L272.656 156.303L286.318 151.876L307.827 144.411L319.558 139.789L357.444 179.51L372.218 148.013Z"
          fill="#FFD55F" />
        <path d="M275.216 113.291L251.514 128.685L243.193 148.161L249.762 147.171L259.164 130.708L275.216 113.291Z"
              fill="#FFD55F" />
        <path
          d="M274.155 115.552L256.225 113.108L238.874 126.024L245.401 127.401L256.954 119.851L265.061 122.576L274.155 115.552Z"
          fill="#FFD55F" />
        <path d="M357.444 179.51L361.724 170.386L322.243 135.152L307.827 144.411L319.558 139.789L357.444 179.51Z"
              fill="#FF5631" />
        <path d="M258.272 132.271L258.227 131.724L266.7 122.531L254.315 132.257L253.156 141.228L258.272 132.271Z"
              fill="#FF5631" />
        <path d="M262.332 121.658L260.339 120.988L271.385 115.174L272.154 115.279L262.332 121.658Z" fill="#FF5631" />
        <path d="M259.299 146.751L260.811 150.059L265.128 147.452L259.74 152.933L259.299 146.751Z" fill="#FFAC5F" />
        <path d="M245.452 142.873L243.193 148.162L249.762 147.171L245.873 146.243L245.452 142.873Z" fill="#FFAC5F" />
        <path d="M238.874 126.024L244.591 121.768L241.509 125.072L245.401 127.401L238.874 126.024Z" fill="#FFAC5F" />
        <path d="M362.482 168.77L322.243 135.152L363.033 167.596L362.482 168.77Z" fill="#FF5631" />
        <path d="M272.783 152.933L274.631 147.586L277.398 144.354L273.02 146.653L272.783 152.933Z" fill="#FFAC5F" />
        <path
          d="M249.067 210.46C258.805 210.46 266.699 209.515 266.699 208.349C266.699 207.184 258.805 206.239 249.067 206.239C239.329 206.239 231.435 207.184 231.435 208.349C231.435 209.515 239.329 210.46 249.067 210.46Z"
          fill="#FF5631" />
        <path d="M235.627 194.876L234.483 194.476L233.862 196.246L235.007 196.646L235.627 194.876Z" fill="#979797" />
        <path d="M267.569 206.042L266.424 205.642L265.804 207.412L266.948 207.812L267.569 206.042Z" fill="#979797" />
        <path
          d="M267.014 207.697C267.833 205.362 261.087 200.879 251.947 197.684C242.806 194.489 234.733 193.791 233.915 196.126C233.097 198.461 239.843 202.944 248.983 206.139C258.123 209.334 266.196 210.032 267.014 207.697Z"
          fill="#979797" />
        <path
          d="M267.545 206.183C268.363 203.848 261.617 199.365 252.477 196.17C243.337 192.974 235.264 192.277 234.446 194.612C233.627 196.947 240.373 201.43 249.514 204.625C258.654 207.82 266.727 208.517 267.545 206.183Z"
          fill="#DEE2E6" />
        <path d="M273.628 143.097L271.355 142.302L269.081 141.508L264.213 150.321L272.656 148.78L273.628 143.097Z"
              fill="#979797" />
        <path
          d="M240.268 193.673C239.91 194.694 243.423 196.881 248.464 198.899L264.582 195.959L265.91 188.197L240.758 192.786L240.268 193.673Z"
          fill="#979797" />
        <path d="M245.497 184.205L267.273 180.232L268.601 172.471L250.116 175.844L245.497 184.205Z" fill="#979797" />
        <path d="M259.473 158.901L254.855 167.263L269.965 164.506L271.293 156.744L259.473 158.901Z" fill="#979797" />
        <path
          d="M251.164 199.912C257.601 202.162 263.158 203.017 263.578 201.821L264.581 195.959L248.464 198.899C249.325 199.244 250.225 199.584 251.164 199.912Z"
          fill="#DEE2E6" />
        <path d="M240.758 192.786L265.91 188.197L267.273 180.232L245.497 184.205L240.758 192.786Z" fill="#DEE2E6" />
        <path d="M250.115 175.844L268.601 172.471L269.965 164.506L254.855 167.263L250.115 175.844Z" fill="#DEE2E6" />
        <path d="M272.656 148.78L264.213 150.321L259.473 158.901L271.293 156.744L272.656 148.78Z" fill="#DEE2E6" />
        <path
          d="M113.145 212.57C123.677 212.57 132.214 210.766 132.214 208.541C132.214 206.317 123.677 204.513 113.145 204.513C102.614 204.513 94.0767 206.317 94.0767 208.541C94.0767 210.766 102.614 212.57 113.145 212.57Z"
          fill="#FF5631" />
        <path
          d="M14.9242 175.651L47.8915 160.11L54.5513 160.52L65.0601 153.589L78.1665 149.288L97.7624 156.418L99.2942 153.844L103.148 155.432L103.504 151.236L109.593 152.46L114.639 148.958L112.443 144.983L114.179 145.175L115.458 141.585L97.2396 122.552L75.0896 112.193L67.7305 114.122L50.4338 133.28L4.78223 144.976L14.9242 175.651Z"
          fill="#FFD55F" />
        <path
          d="M110.712 151.684L109.593 152.46L87.8611 145.953L78.1665 149.288L86.5573 152.37L89.0115 149.118L97.8666 152.843L98.5258 150.209L103.148 155.432L103.525 152.04L109.118 153.468L110.712 151.684Z"
          fill="#FF5631" />
        <path
          d="M105.658 139.437L92.2833 136.491L82.1325 129.586L81.6091 122.429L83.1215 127.28L86.4831 124.067L85.6952 127.858L95.8773 131.182L105.658 139.437Z"
          fill="#FF5631" />
        <path d="M105.658 139.437L112.443 144.983L114.179 145.175L105.658 139.437Z" fill="#FF5631" />
        <path d="M112.443 144.983L105.287 141.372L102.685 146.243L109.593 152.46L114.639 148.958L112.443 144.983Z"
              fill="#FFAC5F" />
        <path d="M50.4338 133.28L6.83562 151.186L4.78223 144.976L50.4338 133.28Z" fill="#FF5631" />
        <path d="M43.448 160.725L47.6597 159.637L44.606 156.365L45.1545 158.942L43.448 160.725Z" fill="#FF5631" />
        <path d="M85.1353 120.039L84.1909 123.264L81.9302 119.537L83.7278 120.505L85.1353 120.039Z" fill="#FFAC5F" />
        <path
          d="M112.443 144.983L113.599 145.753L115.368 149.308L114.815 149.971L113.728 149.988L113.096 150.74L112.094 150.724L114.639 148.958L112.443 144.983Z"
          fill="#FF5631" />
        <path d="M94.5253 206.407H93.2202V208.425H94.5253V206.407Z" fill="#979797" />
        <path d="M130.953 206.407H129.648V208.425H130.953V206.407Z" fill="#979797" />
        <path
          d="M112.094 213.134C122.518 213.134 130.968 210.976 130.968 208.313C130.968 205.65 122.518 203.492 112.094 203.492C101.67 203.492 93.2202 205.65 93.2202 208.313C93.2202 210.976 101.67 213.134 112.094 213.134Z"
          fill="#979797" />
        <path
          d="M112.094 211.407C122.518 211.407 130.968 209.249 130.968 206.586C130.968 203.924 122.518 201.765 112.094 201.765C101.67 201.765 93.2202 203.924 93.2202 206.586C93.2202 209.249 101.67 211.407 112.094 211.407Z"
          fill="#DEE2E6" />
        <path d="M114.688 140.32H112.094H109.501L107.694 151.002L115.723 146.438L114.688 140.32Z" fill="#979797" />
        <path
          d="M98.8022 203.55C98.8022 204.714 103.15 205.688 108.991 205.949L124.318 197.238L122.904 188.881L98.9851 202.475L98.8022 203.55Z"
          fill="#979797" />
        <path d="M100.745 192.074L121.453 180.305L120.039 171.947L102.459 181.939L100.745 192.074Z" fill="#979797" />
        <path d="M105.934 161.402L104.219 171.538L118.588 163.372L117.174 155.014L105.934 161.402Z" fill="#979797" />
        <path
          d="M112.094 206.019C119.435 206.019 125.386 204.913 125.386 203.55L124.318 197.238L108.99 205.949C109.988 205.993 111.024 206.019 112.094 206.019Z"
          fill="#DEE2E6" />
        <path d="M98.9846 202.474L122.904 188.88L121.453 180.305L100.745 192.074L98.9846 202.474Z" fill="#DEE2E6" />
        <path d="M102.459 181.939L120.039 171.947L118.588 163.372L104.219 171.538L102.459 181.939Z" fill="#DEE2E6" />
        <path d="M115.723 146.438L107.694 151.002L105.934 161.402L117.174 155.014L115.723 146.438Z" fill="#DEE2E6" />
        <path d="M105.658 139.437L112.443 144.983L114.639 148.958L109.593 152.46L103.848 150.74L105.658 139.437Z"
              fill="#FFD55F" />
        <path d="M105.287 141.372L112.443 144.983L114.639 148.958L109.593 152.46L102.685 146.243L105.287 141.372Z"
              fill="#FFAC5F" />
        <path d="M107.08 152.948L109.054 153.49L114.414 149.113L109.593 152.46L107.32 151.78L107.08 152.948Z"
              fill="#FF5631" />
        <path d="M109.243 141.851L112.443 144.983L108.844 142.042L109.243 141.851Z" fill="#FF5631" />
        <path d="M241.678 49.804H115.723V103.839H241.678V49.804Z" fill="#FFD55F" />
        <path d="M152.367 37.8658L145.601 49.804H154.245L161.012 37.8658H152.367Z" fill="#FFAC5F" />
        <path d="M186.369 37.8658L179.603 49.804H188.247L195.013 37.8658H186.369Z" fill="#FFAC5F" />
        <path d="M169.368 37.8658L162.601 49.804H171.246L178.012 37.8658H169.368Z" fill="#FFAC5F" />
        <path d="M203.37 37.8658L196.604 49.804H205.248L212.015 37.8658H203.37Z" fill="#FFAC5F" />
        <path
          d="M122.296 37.8658C118.666 37.8658 115.723 40.8051 115.723 44.4301V49.804H120.243L127.009 37.8658H122.296Z"
          fill="#FFAC5F" />
        <path d="M135.366 37.8658L128.6 49.804H137.244L144.01 37.8658H135.366Z" fill="#FFAC5F" />
        <path d="M241.678 44.4301C241.678 41.5292 239.792 39.0732 237.179 38.2047L230.605 49.8041H241.678V44.4301Z"
              fill="#FFAC5F" />
        <path d="M220.371 37.8658L213.604 49.804H222.249L229.016 37.8658H220.371Z" fill="#FFAC5F" />
        <path d="M127.009 37.8658L120.243 49.804H128.6L135.366 37.8658H127.009Z" fill="#FF5631" />
        <path d="M144.01 37.8658L137.244 49.804H145.601L152.367 37.8658H144.01Z" fill="#FF5631" />
        <path d="M161.012 37.8658L154.245 49.804H162.601L169.368 37.8658H161.012Z" fill="#FF5631" />
        <path d="M178.012 37.8658L171.246 49.804H179.603L186.369 37.8658H178.012Z" fill="#FF5631" />
        <path d="M195.013 37.8658L188.247 49.804H196.604L203.37 37.8658H195.013Z" fill="#FF5631" />
        <path d="M212.015 37.8658L205.248 49.804H213.605L220.371 37.8658H212.015Z" fill="#FF5631" />
        <path
          d="M235.105 37.8658H229.016L222.249 49.804H230.605L237.179 38.2047C236.527 37.9876 235.831 37.8658 235.105 37.8658Z"
          fill="#FF5631" />
        <path d="M152.367 115.793L145.601 103.854H154.245L161.012 115.793H152.367Z" fill="#FFAC5F" />
        <path d="M186.369 115.793L179.603 103.854H188.247L195.013 115.793H186.369Z" fill="#FFAC5F" />
        <path d="M169.368 115.793L162.601 103.854H171.246L178.012 115.793H169.368Z" fill="#FFAC5F" />
        <path d="M203.37 115.793L196.604 103.854H205.248L212.015 115.793H203.37Z" fill="#FFAC5F" />
        <path
          d="M122.296 115.793C118.666 115.793 115.723 112.853 115.723 109.228V103.854H120.243L127.009 115.793H122.296Z"
          fill="#FFAC5F" />
        <path d="M135.366 115.793L128.6 103.854H137.244L144.01 115.793H135.366Z" fill="#FFAC5F" />
        <path d="M241.678 109.228C241.678 112.129 239.792 114.585 237.179 115.454L230.605 103.854H241.678V109.228Z"
              fill="#FFAC5F" />
        <path d="M220.371 115.793L213.604 103.854H222.249L229.016 115.793H220.371Z" fill="#FFAC5F" />
        <path d="M127.009 115.793L120.243 103.854H128.6L135.366 115.793H127.009Z" fill="#FF5631" />
        <path d="M144.01 115.793L137.244 103.854H145.601L152.367 115.793H144.01Z" fill="#FF5631" />
        <path d="M161.012 115.793L154.245 103.854H162.601L169.368 115.793H161.012Z" fill="#FF5631" />
        <path d="M178.012 115.793L171.246 103.854H179.603L186.369 115.793H178.012Z" fill="#FF5631" />
        <path d="M195.013 115.793L188.247 103.854H196.604L203.37 115.793H195.013Z" fill="#FF5631" />
        <path d="M212.015 115.793L205.248 103.854H213.605L220.371 115.793H212.015Z" fill="#FF5631" />
        <path
          d="M235.105 115.793H229.016L222.249 103.854H230.605L237.179 115.454C236.527 115.67 235.831 115.793 235.105 115.793Z"
          fill="#FF5631" />
        <path d="M241.678 53.778L115.723 51.044V49.804H241.678V53.778Z" fill="#FF5631" />
        <path
          d="M178.624 60.031L159.025 91.1751C158.096 92.651 159.132 94.5931 160.848 94.5931H179.603H198.357C200.073 94.5931 201.109 92.651 200.18 91.1751L180.58 60.031C180.123 59.3031 179.082 59.3031 178.624 60.031Z"
          fill="white" />
        <path
          d="M178.497 61.7027L159.844 91.7524C159.306 92.6177 159.93 93.7353 160.949 93.7353H179.603H198.256C199.275 93.7353 199.899 92.6177 199.361 91.7524L180.708 61.7027C180.2 60.8838 179.006 60.8838 178.497 61.7027Z"
          fill="#FF5631" />
        <path
          d="M178.487 63.9981L162.538 90.6135C162.02 91.4788 162.644 92.5788 163.654 92.5788H179.603H195.55C196.561 92.5788 197.185 91.4788 196.666 90.6135L180.719 63.9981C180.213 63.156 178.991 63.156 178.487 63.9981Z"
          fill="#FFAC5F" />
        <path
          d="M177.537 85.3983C177.537 84.8499 177.684 84.4357 177.978 84.1546C178.272 83.8735 178.701 83.7336 179.262 83.7336C179.805 83.7336 180.225 83.8773 180.522 84.1646C180.819 84.452 180.968 84.8631 180.968 85.3983C180.968 85.9141 180.818 86.3201 180.517 86.6175C180.216 86.915 179.798 87.0631 179.262 87.0631C178.713 87.0631 178.288 86.9181 177.987 86.6276C177.687 86.3364 177.537 85.9273 177.537 85.3983ZM180.469 82.0493H178.077L177.576 72.4825H180.969L180.469 82.0493Z"
          fill="white" />
        <path d="M143.239 115.75H137.244V188.743H143.239V115.75Z" fill="#DEE2E6" />
        <path
          d="M144.806 192.483H135.366V189.836C135.366 189.231 135.856 188.742 136.461 188.742H143.71C144.316 188.742 144.806 189.232 144.806 189.836V192.483Z"
          fill="#DEE2E6" />
        <path d="M137.244 115.75V121.476L140.934 132.123L143.239 136.656V115.75H137.244Z" fill="#979797" />
        <path d="M137.244 115.75V116.779L143.239 118.239V115.75H137.244Z" fill="#FF5631" />
        <path d="M137.244 188.743H143.239L140.086 183.994L137.244 175.937V188.743Z" fill="#979797" />
        <path d="M137.244 188.131L143.239 188.347V188.743H137.244V188.131Z" fill="#FF5631" />
        <path d="M219.888 115.75H213.893V188.743H219.888V115.75Z" fill="#DEE2E6" />
        <path
          d="M221.454 192.483H212.015V189.836C212.015 189.231 212.505 188.742 213.11 188.742H220.359C220.964 188.742 221.454 189.232 221.454 189.836V192.483Z"
          fill="#DEE2E6" />
        <path d="M213.893 115.75V121.476L217.582 132.123L219.888 136.656V115.75H213.893Z" fill="#979797" />
        <path d="M213.893 115.75V116.779L219.888 118.239V115.75H213.893Z" fill="#FF5631" />
        <path d="M213.893 188.743H219.888L216.734 183.994L213.893 175.937V188.743Z" fill="#979797" />
        <path d="M213.893 188.131L219.888 188.347V188.743H213.893V188.131Z" fill="#FF5631" />
      </svg>
    </Box>
  );
}

export default memo(MaintenanceIllustration);
