// noinspection Annotator

import { createSlice } from '@reduxjs/toolkit';

import { axiosInvocer } from '../../utils/axios'

import { dispatch } from '../store';
import { IBalance, IBalanceFB, IBalancesFB } from '../../@types/wallet';
import { getElements } from '../../utils/ArrayFormater';
import uuidv4 from '../../utils/uuidv4';

const initialState: IBalancesFB = {
  isLoading: false,
  error: null,
  balances: [],
}

const slice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Bill
    getBalancesSuccess(state, action) {
      state.isLoading = false;
      state.balances = action.payload;
    },
  }
});

export default slice.reducer;

// export const {
//     sortByProducts
// } = slice.actions;

export const getListBalances = () => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IBalanceFB[] } = await axiosInvocer.get('/balances', {
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });

    const list = response?.data?.filter((item) => !['TRX', 'ETH']?.includes(item.currency))
      ?.sort((a, b) => a?.available > b?.available ? -1 : 1 )
      ?.map((item) => {
        const env = item?.env
            ?.replaceAll('EU', 'OTC')
            ?.replaceAll('MAIN', 'Invoicer');
        const vaultName = item?.vaultName
          ?.split('_')
        return ({
          ...item,
          id: uuidv4(),
          env,
          vaultName: vaultName?.length > 1 ? vaultName[vaultName?.length-1] : 'Current',
          vaultId: item?.vaultId
        })
      });

    const listENV = getElements(list, 'env');
    const listVault = getElements(list, 'vaultName');

    let finishList: IBalance[] = [];

    listENV.forEach(( env => listVault.forEach((vault) => {
      const listVault = list?.filter((balance) => balance?.env === env && balance?.vaultName === vault);
      if (listVault.length > 0) {
        finishList.push({
          id: uuidv4(),
          env,
          vault,
          vaultId: listVault[0]?.vaultId,
          listVault
        });
      }
    })));


    dispatch(slice.actions.getBalancesSuccess(finishList));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}