import { IInvoice } from '../@types/invoice';

export const CSVHeaders = [
  { label: "ID", key: "id" },
  { label: "Bill number", key: "invoiceNumber" },
  { label: "Bill Date", key: "invoiceDate" },
  { label: "Due Date", key: "dueDate" },
  { label: "Recipient", key: "recipient" },
  { label: "Payer", key: "payer" },
  { label: "Payment method", key: "paymentMethod" },
  { label: "Currency", key: "currency" },
  { label: "Chain", key: "chain" },
  { label: "Wallet", key: "wallet" },
  { label: "Tax", key: "tax" },
  { label: "Discount", key: "discount" },
  { label: "Total", key: "total" },
  { label: "Status", key: "status" },
  { label: "TXID", key: "txid" },
  { label: "Paid at", key: "paidAt" },
  { label: "Created at", key: "createdDatetime" },
  { label: "Updated at", key: "updatedDatetime" },
]

export const columnNames: { [key in keyof IInvoice]?: string } = {
  invoiceNumber: "Invoice Number",
  externalRecipient: "Recipient",
  createdUser: "Payer",
  createdDatetime: "Created",
  paidAt: "Paid Date",
  status: "Status",
  transactionHash: "Hash",
  cryptoCurrencyAddress: "Address",
  total: "Total",
}

export const CSVPaymentsExampleHeaders = [
  { label: "invoiceNumber", key: "invoiceNumber" },
  { label: "currency", key: "currency" },
  { label: "address", key: "address" },
  { label: "amount", key: "total" },
]

export const CSVRecipientsExampleHeaders = [
  { label: "name", key: "name" },
  { label: "currency", key: "currency" },
  { label: "address", key: "address" },
  { label: "addressName", key: "addressName" },
]