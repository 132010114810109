// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent, Stack } from '@mui/material';
//
import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2', textAlign: 'center' }}> {content} </DialogContent>}

      <DialogActions>
        <Stack spacing={2} direction="row" width={1} justifyContent="center">
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          {action}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
