import { createSlice } from '@reduxjs/toolkit';

import { axiosInvocer } from '../../utils/axios';

import { dispatch } from '../store';
import {
  RecipientsState,
  IRecipientState,
  IExternalRecipient,
  IExternalRecipientState, IQueryNetwork,
} from '../../@types/recipient';

const initialState: RecipientsState = {
  isLoading: false,
  error: null,
  internal: null,
  network: null,
  recipient: null,
  networkRecipient: null,
};

const slice = createSlice({
  name: 'recipients',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MERCHANT
    getRecipientSuccess(state, action) {
      state.isLoading = false;
      state.recipient = action.payload;
      state.error = null;
    },

    // GET MERCHANT
    getNetworkRecipientSuccess(state, action) {
      state.isLoading = false;
      state.networkRecipient = action.payload;
      state.error = null;
    },

    // GET LIST INTERNAL CONTRACTORS
    getInternalRecipientsSuccess(state, action) {
      state.isLoading = false;
      state.internal = action.payload;
      state.error = null;
    },

    createSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },

    createRecipientSuccess(state, action) {
      state.isLoading = false;
      state.recipient = action.payload;
      state.error = null;
    },

    // GET LIST NETWORK CONTRACTORS
    getNetworkRecipientsSuccess(state, action) {
      state.isLoading = false;
      state.network = action.payload;
      state.error = null;
    },

    updateSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },

    clearErrorRecipient(state) {
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default slice.reducer;

export const {
  clearErrorRecipient,
} = slice.actions;

export const getInternalRecipients = () => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IRecipientState } = await axiosInvocer.get('/users/recipients', {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getInternalRecipientsSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getExternalRecipients = (query: IQueryNetwork) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IExternalRecipientState } = await axiosInvocer.get('/external_recipients', {
      params: query ?? {},
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getNetworkRecipientsSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getNetworkRecipient = (id: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IExternalRecipient } = await axiosInvocer.get(`/external_recipients/${id}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getNetworkRecipientSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export function batchCreateRecipients(recipients: IExternalRecipient[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axiosInvocer.post('/external_recipients/batch', recipients, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      });
      dispatch(slice.actions.createSuccess());
      return resp;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export const batchCreateRecipients = (recipients: IExternalRecipient[]) => async () => {
//   dispatch(slice.actions.startLoading());
//   try {
//     const resp = await axiosInvocer.post('/external_recipients/batch', recipients, {
//       headers: {
//         "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`,
//       },
//     });
//     dispatch(slice.actions.createSuccess());
//     return resp;
//   } catch (error) {
//     dispatch(slice.actions.hasError(error));
//   }
// }

export const createRecipient = (recipient: any) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axiosInvocer.post('/external_recipients', recipient, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch(slice.actions.createRecipientSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const deleteInternalRecipient = (recipientID: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    await axiosInvocer.delete(`/parties/recipient/${recipientID}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch(slice.actions.updateSuccess());
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
};

export const addWalletInternalRecipient = (recipientID: string, wallet: object) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    await axiosInvocer.post(`/external_recipient_addresses/external_recipient/${recipientID}`, wallet, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch(slice.actions.updateSuccess());
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
};

export const deleteInternalWalletRecipient = (walletID: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    await axiosInvocer.delete(`/external_recipient_addresses/${walletID}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch(slice.actions.updateSuccess());
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
};

export const deleteNetworkRecipient = (recipientID: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    await axiosInvocer.delete(`/external_recipients/${recipientID}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    });
    dispatch(slice.actions.updateSuccess());
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
};