import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue, symbol = '$') {
  const format = number ? `${symbol} ${numeral(number).format(' 0,0[.]00')}` : '';

  return result(format, '.00');
}

export function fCurrencyCrypto(number: InputValue, symbol?: string | null | undefined) {
  const format = number ? `${numeral(number).format('0,0[.][0000000]')} ${symbol ?? ''}` : '';

  return result(format, '.00');
}

export function fCurrencyCryptoFormat(number: InputValue, symbol?: string | null | undefined) {
  if (number === null || number === undefined) {
    return '';
  }

  const isInteger = Number.isInteger(Number(number));
  const format = isInteger
    ? `${numeral(number).format('0,0')} ${symbol ?? ''}`
    : `${numeral(number).format('0,0.0000000')} ${symbol ?? ''}`;

  return format.trim();
}

export const formatRateNumber = (input: number): string => Number.isInteger(input) ? input.toString() : input.toFixed(8);

export function fPercent(number: InputValue, symbol?: string | null | undefined) {
  const format = number ? `${symbol ?? ''}${numeral(Number(number) / 100).format('0.[00]%')}` : '';

  return result(format, '.00');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fCurrency5(number: InputValue, symbol = '$') {
  const format = number ? `${symbol} ${numeral(number).format(' 0,0[.]00000')}` : '';

  return result(format, '.00000');
}