import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function ExpandIcon({ ...other }: BoxProps) {
  return (
    <Box {...other} display="flex" justifyContent="cener" alignItems="center" width={other.width} height={other.width}>
      <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33333 6L8 10.6667L12.6667 6" stroke="#404040" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round" />
      </svg>
    </Box>
  );
}

export default memo(ExpandIcon);
