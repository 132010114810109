// @mui
import { Dialog, Button, DialogContent, Stack, DialogTitle } from '@mui/material';
//
import { ErrorDialogProps } from './types';
import { ErrorIcon } from '../../assets/icons';

// ----------------------------------------------------------------------

export default function ErrorDialog({
  content,
  action,
  open,
  onClose,
  title,
  ...other
}: ErrorDialogProps) {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      {...other}
    >

      {title && (
        <DialogTitle sx={{ pb: .5, textAlign: 'center' }}>
          <ErrorIcon width={80} sx={{ pb: 1, margin: 'auto' }} />
          {title}
        </DialogTitle>
      )}

      {content && (
        <DialogContent sx={{ typography: 'body2' }}>
          {content}
        </DialogContent>
      )}

      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" sx={{ p: 2, pt: 4 }}>
        {action}
        <Button variant="outlined" color="inherit" onClick={onClose} sx={{ width: 166, height: 44 }}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
}
