import { IBalance } from '../@types/wallet';

export const extractCurrencyNames = (data: IBalance[]): string[] => {
  const invoicerItem = data?.find(item => item?.env === 'Invoicer');


  if (invoicerItem?.listVault) {
    return invoicerItem?.listVault.map(vaultItem => `${vaultItem?.currency}${vaultItem?.chain}`);
  }

  return [];
};