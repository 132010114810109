import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function EditIcon({ ...other }: BoxProps) {
  return (
    <Box {...other} display="flex" justifyContent="cener" alignItems="center" width={other.width} height={other.width}>
      <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.095 12.0026C19.8564 12.0026 19.6276 12.0974 19.4588 12.2661C19.2901 12.4349 19.1953 12.6637 19.1953 12.9023V18.3008C19.1953 18.5394 19.1005 18.7683 18.9318 18.937C18.763 19.1057 18.5342 19.2005 18.2956 19.2005H5.69922C5.46059 19.2005 5.23174 19.1057 5.06301 18.937C4.89427 18.7683 4.79948 18.5394 4.79948 18.3008V5.70443C4.79948 5.46581 4.89427 5.23696 5.06301 5.06822C5.23174 4.89949 5.46059 4.8047 5.69922 4.8047H11.0977C11.3363 4.8047 11.5651 4.7099 11.7339 4.54117C11.9026 4.37243 11.9974 4.14358 11.9974 3.90496C11.9974 3.66633 11.9026 3.43748 11.7339 3.26874C11.5651 3.10001 11.3363 3.00522 11.0977 3.00522H5.69922C4.98334 3.00522 4.29678 3.2896 3.79058 3.7958C3.28438 4.302 3 4.98856 3 5.70443V18.3008C3 19.0167 3.28438 19.7032 3.79058 20.2094C4.29678 20.7156 4.98334 21 5.69922 21H18.2956C19.0114 21 19.698 20.7156 20.2042 20.2094C20.7104 19.7032 20.9948 19.0167 20.9948 18.3008V12.9023C20.9948 12.6637 20.9 12.4349 20.7313 12.2661C20.5625 12.0974 20.3337 12.0026 20.095 12.0026ZM6.59896 12.6864V16.5013C6.59896 16.7399 6.69375 16.9688 6.86248 17.1375C7.03122 17.3063 7.26007 17.401 7.4987 17.401H11.3136C11.432 17.4017 11.5494 17.379 11.659 17.3343C11.7686 17.2895 11.8683 17.2235 11.9524 17.1401L18.1786 10.9049L20.7339 8.40365C20.8182 8.32001 20.8851 8.2205 20.9308 8.11086C20.9765 8.00121 21 7.88361 21 7.76484C21 7.64606 20.9765 7.52846 20.9308 7.41882C20.8851 7.30918 20.8182 7.20966 20.7339 7.12602L16.919 3.26614C16.8353 3.18181 16.7358 3.11487 16.6262 3.0692C16.5165 3.02352 16.3989 3 16.2802 3C16.1614 3 16.0438 3.02352 15.9341 3.0692C15.8245 3.11487 15.725 3.18181 15.6413 3.26614L13.1041 5.8124L6.85988 12.0476C6.77649 12.1317 6.71052 12.2314 6.66574 12.341C6.62097 12.4506 6.59827 12.568 6.59896 12.6864ZM16.2802 5.17359L18.8264 7.71985L17.5488 8.99748L15.0025 6.45122L16.2802 5.17359ZM8.39843 13.0553L13.7339 7.71985L16.2802 10.2661L10.9447 15.6016H8.39843V13.0553Z"
          fill="#979797" />
      </svg>
    </Box>
  );
}

export default memo(EditIcon);
