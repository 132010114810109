import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Container } from '@mui/material';

import { useSettingsContext } from '../../../components/settings';
import PreviewForm from '../../../sections/@dashboard/csv/PreviewForm';
import MotionContainer from '../../../components/animate/MotionContainer';

const UploadCSVPage = () => {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> File upload | Invoicer Payperless</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'} component={MotionContainer}>
        <PreviewForm />
      </Container>
    </>
  )
}

export default UploadCSVPage;