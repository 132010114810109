import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function ComingSoonIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other} sx={{ minWidth: { md: '358px' }, height: { md: 'auto' } }}>
      <svg width="100%" height="100%" viewBox="0 0 358 239" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M276.038 69.6356V176.845C276.038 180.212 273.302 182.951 269.94 182.951H113.058C109.696 182.951 106.96 180.212 106.96 176.845V69.6356H276.038ZM277.231 68.4406H105.767V176.845C105.767 180.878 109.031 184.146 113.058 184.146H269.94C273.967 184.146 277.231 180.878 277.231 176.845V68.4406Z"
          fill="#DEE2E6" />
        <path
          d="M269.94 52.8447H113.058C109.031 52.8447 105.767 56.1136 105.767 60.1461V68.4406H277.231V60.1461C277.231 56.1136 273.967 52.8447 269.94 52.8447Z"
          fill="#DEE2E6" />
        <path
          d="M182.06 119.199H118.801V89.7666H182.06V119.199ZM119.398 118.601H181.464V90.3641H119.398V118.601ZM153.627 168.122H118.802V133.248H153.627V168.122ZM119.398 167.525H153.03V133.846H119.398V167.525ZM270.263 117.71H193.686V82.8356H270.263V117.71ZM194.282 117.112H269.666V83.4331H194.282V117.112ZM270.263 168.437H193.686V122.753H270.263V168.437ZM194.282 167.84H269.666V123.351H194.282V167.84ZM182.834 168.122H158.82V133.248H182.834V168.122ZM159.417 167.525H182.237V133.846H159.417V167.525Z"
          fill="#DEE2E6" />
        <path d="M269.803 122.772L193.844 167.893L194.148 168.407L270.108 123.285L269.803 122.772Z" fill="#DEE2E6" />
        <path d="M194.14 122.797L193.836 123.311L269.815 168.399L270.119 167.885L194.14 122.797Z" fill="#DEE2E6" />
        <path d="M194.094 82.8455L193.849 83.3903L269.831 117.662L270.076 117.118L194.094 82.8455Z" fill="#DEE2E6" />
        <path d="M269.853 82.8932L193.849 117.117L194.094 117.662L270.098 83.4381L269.853 82.8932Z" fill="#DEE2E6" />
        <path d="M119.222 89.784L118.972 90.3269L181.636 119.159L181.885 118.616L119.222 89.784Z" fill="#DEE2E6" />
        <path d="M181.63 89.7681L118.985 118.642L119.235 119.184L181.879 90.3109L181.63 89.7681Z" fill="#DEE2E6" />
        <path d="M119.308 133.338L118.887 133.76L153.116 168.036L153.538 167.614L119.308 133.338Z" fill="#DEE2E6" />
        <path d="M153.116 133.335L118.887 167.612L119.309 168.035L153.537 133.758L153.116 133.335Z" fill="#DEE2E6" />
        <path d="M182.275 133.366L158.881 167.658L159.374 167.995L182.769 133.703L182.275 133.366Z" fill="#DEE2E6" />
        <path d="M159.361 133.39L158.868 133.727L182.285 168.004L182.778 167.666L159.361 133.39Z" fill="#DEE2E6" />
        <path
          d="M250.475 62.7853C251.657 62.7853 252.615 61.826 252.615 60.6426C252.615 59.4593 251.657 58.5 250.475 58.5C249.294 58.5 248.336 59.4593 248.336 60.6426C248.336 61.826 249.294 62.7853 250.475 62.7853Z"
          fill="#68CA87" />
        <path
          d="M259.089 62.7853C260.27 62.7853 261.228 61.826 261.228 60.6426C261.228 59.4593 260.27 58.5 259.089 58.5C257.907 58.5 256.949 59.4593 256.949 60.6426C256.949 61.826 257.907 62.7853 259.089 62.7853Z"
          fill="#68CA87" />
        <path
          d="M267.702 62.7853C268.883 62.7853 269.841 61.826 269.841 60.6426C269.841 59.4593 268.883 58.5 267.702 58.5C266.52 58.5 265.562 59.4593 265.562 60.6426C265.562 61.826 266.52 62.7853 267.702 62.7853Z"
          fill="#68CA87" />
        <path
          d="M193.992 175.504H240.43C243.042 175.504 245.159 173.384 245.159 170.769V138.615C245.159 136 243.042 133.88 240.43 133.88H193.992C191.38 133.88 189.263 136 189.263 138.615V170.769C189.263 173.384 191.38 175.504 193.992 175.504Z"
          fill="#E1F4E7" />
        <path
          d="M245.25 155.685L251.629 164.292L257.835 204.724H239.482L241.667 189.934L236.685 182.757L232.665 175.669H242.715L241.055 170.506L234.5 164.204L235.243 160.091L245.25 164.905V155.685Z"
          fill="#FFD55F" />
        <path
          d="M235.195 160.358L234.85 160.237L233.889 164.496L240.589 171.235L241.382 174.604C241.491 175.069 241.14 175.514 240.663 175.514L232.58 175.519L233.025 176.304L241.462 176.427L242.569 177.126L247.55 183.253L242.714 175.669L241.054 170.505L234.5 164.204L235.195 160.358Z"
          fill="#FF5631" />
        <path
          d="M244.753 164.687L247.551 166.217L246.386 163.723L245.785 156.408L245.16 155.564L245.148 164.627L244.753 164.687Z"
          fill="#FF5631" />
        <path d="M234.5 164.204L238.127 165.648L239.351 162.935L235.243 160.091L234.5 164.204Z" fill="#FFAC5F" />
        <path
          d="M319.007 32.2256L296.978 46.1754L286.478 56.2451L283.408 79.6916L285.666 79.7023L287.713 77.6708L288.845 81.9125L293.676 81.0133L294.545 78.1357L295.556 81.0127L297.999 80.9989L300.703 79.1927L300.972 76.5201L302.077 77.7527L305.452 77.6768L307.433 75.1518L307.401 72.3316L309.026 73.9341L313.647 71.9336L315.954 57.314L331.534 50.0054L319.007 32.2256Z"
          fill="#FFD55F" />
        <path
          d="M286.876 78.5013L289.245 72.3728L291.708 70.5205L293.059 64.6901L293.925 63.6642L296.627 64.6513L298.645 62.8743L296.786 66.0733L295.685 78.931L295.086 79.6766L294.545 78.1422L294.444 74.1079L291.991 72.8675L290.106 73.7793L287.713 77.6708L286.876 78.5013Z"
          fill="#FF5631" />
        <path
          d="M300.493 62.6264L303.345 60.5184L304.681 60.9038L302.966 62.1383L302.644 68.8661L301.416 77.0154L300.99 76.5685L302.026 68.6976L301.758 63.6493L300.493 62.6264Z"
          fill="#FF5631" />
        <path
          d="M305.511 61.4827L308.332 59.8492L307.097 63.4987L307.82 67.3872L307.843 72.7755L307.401 72.3316L307.251 67.4631L306.495 63.4539L305.511 61.4827Z"
          fill="#FF5631" />
        <path
          d="M310.403 60.1049L312.058 66.9756L312.105 69.1977L312.757 67.9806L314.106 68.4586L312.678 65.9825L311.397 60.9761L310.403 60.1049Z"
          fill="#FF5631" />
        <path d="M315.954 57.314L316.102 55.1965L327.751 44.6715L331.534 50.0054L315.954 57.314Z" fill="#FF5631" />
        <path d="M284.004 75.1345L285.259 75.3711L285.666 79.7024L283.408 79.6916L284.004 75.1345Z" fill="#FFAC5F" />
        <path d="M291.991 72.8676L290.163 75.8431L292.92 77.8268L294.484 75.6788L294.444 74.108L291.991 72.8676Z"
              fill="#FFAC5F" />
        <path
          d="M240.433 115.379H290.581C293.192 115.379 295.309 113.259 295.309 110.644V75.7283C295.309 73.1131 293.192 70.9932 290.581 70.9932H240.433C237.822 70.9932 235.705 73.1131 235.705 75.7283V110.644C235.705 113.259 237.822 115.379 240.433 115.379Z"
          fill="#E1F4E7" />
        <path
          d="M284.839 68.7669L283.408 79.6916L285.666 79.7024L287.713 77.6708L290.107 73.7793L291.991 72.8675L293.247 67.5098L284.839 68.7669Z"
          fill="#FFD55F" />
        <path d="M283.408 79.6916L285.666 79.7024V75.1345H284.004L283.408 79.6916Z" fill="#FFAC5F" />
        <path d="M293.059 64.6901L291.708 70.5205L291.991 72.8675L294.484 66.5639L293.059 64.6901Z" fill="#FF5631" />
        <path d="M293.059 64.6901L287.713 77.6708L285.666 79.7023L288.915 77.8268L293.47 70.9938L293.059 64.6901Z"
              fill="#FF5631" />
        <path
          d="M72.0302 99.243L87.2452 103.074L88.9362 109.668L81.4617 122.231L72.0302 122.766L68.6489 117.598L71.7635 114.658L75.3226 114.212L77.1019 110.47L70.7844 109.935L65.9794 114.212L68.1155 101.025L72.0302 99.243Z"
          fill="#FFD55F" />
        <path
          d="M97.4112 85.8554L93.6743 90.0654L83.0638 89.1297L70.6513 101.559L66.7807 113.922L73.7206 122.075L81.3286 121.674L89.0698 117.865H99.1463L102.617 123.947L92.006 126.486L68.9824 142.525L55.0353 145.465L40.4206 159.632L25.606 138.248L43.9576 127.956L55.2358 98.3521L74.0959 82.7143L88.6688 81.9125L97.4112 85.8554Z"
          fill="#FFD55F" />
        <path
          d="M72.7767 99.4306L70.6513 101.559L66.9639 113.336L70.7844 109.935L77.1019 110.47L75.3226 114.212L71.7635 114.658L69.3476 116.939L73.7206 122.075L81.3287 121.674L81.9856 121.351L83.2822 119.172L79.4265 120.371L72.5195 117.832L73.0535 116.195L74.6884 115.76L78.4921 117.932L76.3901 114.758L79.3597 111.004L83.2822 107.708L76.7236 108.944L72.2528 106.204L72.3864 101.025L74.6985 99.9146L72.7767 99.4306Z"
          fill="#FF5631" />
        <path d="M68.9824 142.525L53.1666 143.126L37.1825 154.958L40.4206 159.632L55.0354 145.465L68.9824 142.525Z"
              fill="#FF5631" />
        <path d="M95.5544 87.9472L89.6032 86.2784V84.407L91.3753 83.1326L97.4112 85.8554L95.5544 87.9472Z"
              fill="#FFAC5F" />
        <path d="M101.06 121.219L92.3395 122.543L92.006 126.486L102.617 123.947L101.06 121.219Z" fill="#FFAC5F" />
        <path d="M99.5115 118.505L86.6515 119.055L88.9063 117.945H99.1922L99.5115 118.505Z" fill="#FF5631" />
        <path d="M93.9046 89.8055L93.6743 90.0654L92.2882 89.9429L93.3061 89.5701L93.9046 89.8055Z" fill="#FFD55F" />
        <path
          d="M157.09 117.945H92.5615C91.4475 117.945 90.5442 117.041 90.5442 115.925V91.8095C90.5442 90.6939 91.4475 89.7893 92.5615 89.7893H157.09C158.204 89.7893 159.108 90.6939 159.108 91.8095V115.925C159.108 117.041 158.205 117.945 157.09 117.945Z"
          fill="#E1F4E7" />
        <path
          d="M276.137 79.6766H240.028C239.857 79.6766 239.718 79.538 239.718 79.3665V77.3249C239.718 77.1534 239.856 77.0148 240.028 77.0148H276.137C276.307 77.0148 276.446 77.1534 276.446 77.3249V79.3665C276.446 79.538 276.308 79.6766 276.137 79.6766Z"
          fill="#68CA87" />
        <path
          d="M132.208 96.0935H96.0986C95.9273 96.0935 95.7889 95.9549 95.7889 95.7834V93.7417C95.7889 93.5703 95.9273 93.4316 96.0986 93.4316H132.208C132.378 93.4316 132.517 93.5703 132.517 93.7417V95.7834C132.517 95.9543 132.378 96.0935 132.208 96.0935Z"
          fill="#68CA87" />
        <path
          d="M132.208 114.946H96.0986C95.9273 114.946 95.7889 114.807 95.7889 114.636V98.7208C95.7889 98.5499 95.9273 98.4106 96.0986 98.4106H132.208C132.378 98.4106 132.517 98.5493 132.517 98.7208V114.636C132.517 114.807 132.378 114.946 132.208 114.946Z"
          fill="#68CA87" />
        <path
          d="M155.345 114.946H135.744C135.573 114.946 135.434 114.807 135.434 114.636V98.7208C135.434 98.5499 135.573 98.4106 135.744 98.4106H155.345C155.516 98.4106 155.655 98.5493 155.655 98.7208V114.636C155.654 114.807 155.516 114.946 155.345 114.946Z"
          fill="#68CA87" />
        <path
          d="M214.879 154.369H195.278C195.107 154.369 194.968 154.23 194.968 154.059V138.144C194.968 137.972 195.106 137.834 195.278 137.834H214.879C215.05 137.834 215.188 137.972 215.188 138.144V154.059C215.188 154.23 215.05 154.369 214.879 154.369Z"
          fill="#68CA87" />
        <path
          d="M214.879 158.364H195.278C195.107 158.364 194.968 158.225 194.968 158.054V156.334C194.968 156.163 195.106 156.024 195.278 156.024H214.879C215.05 156.024 215.188 156.163 215.188 156.334V158.054C215.188 158.225 215.05 158.364 214.879 158.364Z"
          fill="#68CA87" />
        <path
          d="M240.072 158.364H220.471C220.3 158.364 220.161 158.225 220.161 158.054V156.334C220.161 156.163 220.299 156.024 220.471 156.024H240.072C240.242 156.024 240.381 156.163 240.381 156.334V158.054C240.381 158.225 240.242 158.364 240.072 158.364Z"
          fill="#68CA87" />
        <path
          d="M207.056 162.061H195.278C195.107 162.061 194.968 161.922 194.968 161.75V160.031C194.968 159.86 195.106 159.721 195.278 159.721H207.056C207.227 159.721 207.366 159.859 207.366 160.031V161.75C207.366 161.921 207.227 162.061 207.056 162.061Z"
          fill="#68CA87" />
        <path
          d="M232.248 162.061H220.47C220.299 162.061 220.16 161.922 220.16 161.75V160.031C220.16 159.86 220.299 159.721 220.47 159.721H232.248C232.419 159.721 232.558 159.859 232.558 160.031V161.75C232.558 161.921 232.42 162.061 232.248 162.061Z"
          fill="#68CA87" />
        <path
          d="M212.448 166.217H195.278C195.107 166.217 194.968 166.079 194.968 165.907V164.188C194.968 164.017 195.106 163.878 195.278 163.878H212.448C212.619 163.878 212.758 164.016 212.758 164.188V165.907C212.758 166.079 212.619 166.217 212.448 166.217Z"
          fill="#68CA87" />
        <path
          d="M201.576 170.374H195.278C195.107 170.374 194.968 170.235 194.968 170.064V168.344C194.968 168.174 195.106 168.034 195.278 168.034H201.576C201.747 168.034 201.885 168.173 201.885 168.344V170.064C201.885 170.235 201.747 170.374 201.576 170.374Z"
          fill="#68CA87" />
        <path
          d="M226.768 170.374H220.47C220.299 170.374 220.16 170.235 220.16 170.064V168.344C220.16 168.174 220.299 168.034 220.47 168.034H226.768C226.939 168.034 227.078 168.173 227.078 168.344V170.064C227.078 170.235 226.939 170.374 226.768 170.374Z"
          fill="#68CA87" />
        <path
          d="M240.072 154.369H220.471C220.3 154.369 220.161 154.23 220.161 154.059V138.144C220.161 137.972 220.299 137.834 220.471 137.834H240.072C240.242 137.834 240.381 137.972 240.381 138.144V154.059C240.381 154.23 240.242 154.369 240.072 154.369Z"
          fill="#68CA87" />
        <path
          d="M155.345 96.0935H135.744C135.573 96.0935 135.434 95.9549 135.434 95.7834V93.7417C135.434 93.5703 135.573 93.4316 135.744 93.4316H155.345C155.516 93.4316 155.655 93.5703 155.655 93.7417V95.7834C155.654 95.9543 155.516 96.0935 155.345 96.0935Z"
          fill="#68CA87" />
        <path
          d="M276.137 84.465H240.028C239.857 84.465 239.718 84.3263 239.718 84.1549V82.1132C239.718 81.9417 239.856 81.8031 240.028 81.8031H276.137C276.307 81.8031 276.446 81.9417 276.446 82.1132V84.1549C276.446 84.3263 276.308 84.465 276.137 84.465Z"
          fill="#68CA87" />
        <path
          d="M290.413 89.2534H240.028C239.857 89.2534 239.718 89.1147 239.718 88.9432V86.9016C239.718 86.7301 239.856 86.5915 240.028 86.5915H290.413C290.584 86.5915 290.722 86.7301 290.722 86.9016V88.9432C290.722 89.1147 290.584 89.2534 290.413 89.2534Z"
          fill="#68CA87" />
        <path
          d="M290.413 94.0417H240.028C239.857 94.0417 239.718 93.9031 239.718 93.7316V91.69C239.718 91.5185 239.856 91.3799 240.028 91.3799H290.413C290.584 91.3799 290.722 91.5185 290.722 91.69V93.7316C290.722 93.9031 290.584 94.0417 290.413 94.0417Z"
          fill="#68CA87" />
        <path
          d="M290.413 98.8301H240.028C239.857 98.8301 239.718 98.6914 239.718 98.52V96.4783C239.718 96.3074 239.856 96.1682 240.028 96.1682H290.413C290.584 96.1682 290.722 96.3068 290.722 96.4783V98.52C290.722 98.6914 290.584 98.8301 290.413 98.8301Z"
          fill="#68CA87" />
        <path
          d="M290.413 103.618H240.028C239.857 103.618 239.718 103.48 239.718 103.308V101.267C239.718 101.095 239.856 100.957 240.028 100.957H290.413C290.584 100.957 290.722 101.095 290.722 101.267V103.308C290.722 103.48 290.584 103.618 290.413 103.618Z"
          fill="#68CA87" />
        <path
          d="M290.413 108.407H240.028C239.857 108.407 239.718 108.268 239.718 108.097V106.055C239.718 105.884 239.856 105.745 240.028 105.745H290.413C290.584 105.745 290.722 105.884 290.722 106.055V108.097C290.722 108.268 290.584 108.407 290.413 108.407Z"
          fill="#68CA87" />
      </svg>

    </Box>
  );
}

export default memo(ComingSoonIllustration);
