import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function SeverErrorIllustration({ ...other }: BoxProps) {

  return (
    <Box {...other} sx={{ minWidth: { md: '412px' }, height: { md: 'auto' } }}>
      <svg width="100%" height="100%" viewBox="0 0 412 275" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M90.1997 127.586H88.9294V128.856H90.1997V127.586Z" fill="#5412A0" />
          <path d="M95.2203 127.586H93.95V128.856H95.2203V127.586Z" fill="#5412A0" />
          <path d="M100.24 127.586H98.9698V128.856H100.24V127.586Z" fill="#5412A0" />
          <path d="M105.261 127.586H103.99V128.856H105.261V127.586Z" fill="#5412A0" />
          <path d="M110.281 127.586H109.01V128.856H110.281V127.586Z" fill="#5412A0" />
          <path d="M115.301 127.586H114.031V128.856H115.301V127.586Z" fill="#5412A0" />
          <path d="M120.322 127.586H119.052V128.856H120.322V127.586Z" fill="#5412A0" />
          <path d="M125.342 127.586H124.071V128.856H125.342V127.586Z" fill="#5412A0" />
          <path d="M130.362 127.586H129.092V128.856H130.362V127.586Z" fill="#5412A0" />
          <path d="M90.1997 132.69H88.9294V133.961H90.1997V132.69Z" fill="#5412A0" />
          <path d="M95.2203 132.69H93.95V133.961H95.2203V132.69Z" fill="#5412A0" />
          <path d="M100.24 132.69H98.9698V133.961H100.24V132.69Z" fill="#5412A0" />
          <path d="M105.261 132.69H103.99V133.961H105.261V132.69Z" fill="#5412A0" />
          <path d="M110.281 132.69H109.01V133.961H110.281V132.69Z" fill="#5412A0" />
          <path d="M115.301 132.69H114.031V133.961H115.301V132.69Z" fill="#5412A0" />
          <path d="M120.322 132.69H119.052V133.961H120.322V132.69Z" fill="#5412A0" />
          <path d="M125.342 132.69H124.071V133.961H125.342V132.69Z" fill="#5412A0" />
          <path d="M130.362 132.69H129.092V133.961H130.362V132.69Z" fill="#5412A0" />
          <path d="M135.382 132.69H134.112V133.961H135.382V132.69Z" fill="#5412A0" />
          <path d="M90.1994 153.106H88.9291V154.377H90.1994V153.106Z" fill="#5412A0" />
          <path d="M95.2193 153.106H93.949V154.377H95.2193V153.106Z" fill="#5412A0" />
          <path d="M100.24 153.106H98.9695V154.377H100.24V153.106Z" fill="#5412A0" />
          <path d="M105.26 153.106H103.99V154.377H105.26V153.106Z" fill="#5412A0" />
          <path d="M110.28 153.106H109.01V154.377H110.28V153.106Z" fill="#5412A0" />
          <path d="M115.3 153.106H114.03V154.377H115.3V153.106Z" fill="#5412A0" />
          <path d="M120.322 153.106H119.051V154.377H120.322V153.106Z" fill="#5412A0" />
          <path d="M125.341 153.106H124.071V154.377H125.341V153.106Z" fill="#5412A0" />
          <path d="M130.362 153.106H129.092V154.377H130.362V153.106Z" fill="#5412A0" />
          <path d="M135.382 153.106H134.112V154.377H135.382V153.106Z" fill="#5412A0" />
          <path d="M140.403 153.106H139.132V154.377H140.403V153.106Z" fill="#5412A0" />
          <path d="M145.422 153.106H144.152V154.377H145.422V153.106Z" fill="#5412A0" />
          <path d="M150.444 153.106H149.173V154.377H150.444V153.106Z" fill="#5412A0" />
          <path d="M155.464 153.106H154.193V154.377H155.464V153.106Z" fill="#5412A0" />
          <path d="M90.1994 158.214H88.9291V159.484H90.1994V158.214Z" fill="#5412A0" />
          <path d="M95.2193 158.214H93.949V159.484H95.2193V158.214Z" fill="#5412A0" />
          <path d="M100.24 158.214H98.9695V159.484H100.24V158.214Z" fill="#5412A0" />
          <path d="M105.26 158.214H103.99V159.484H105.26V158.214Z" fill="#5412A0" />
          <path d="M110.28 158.214H109.01V159.484H110.28V158.214Z" fill="#5412A0" />
          <path d="M115.3 158.214H114.03V159.484H115.3V158.214Z" fill="#5412A0" />
          <path d="M120.322 158.214H119.051V159.484H120.322V158.214Z" fill="#5412A0" />
          <path d="M125.341 158.214H124.071V159.484H125.341V158.214Z" fill="#5412A0" />
          <path d="M130.362 158.214H129.092V159.484H130.362V158.214Z" fill="#5412A0" />
          <path d="M135.382 158.214H134.112V159.484H135.382V158.214Z" fill="#5412A0" />
          <path d="M140.403 158.214H139.132V159.484H140.403V158.214Z" fill="#5412A0" />
          <path d="M145.422 158.214H144.152V159.484H145.422V158.214Z" fill="#5412A0" />
          <path d="M150.444 158.214H149.173V159.484H150.444V158.214Z" fill="#5412A0" />
          <path d="M155.464 158.214H154.193V159.484H155.464V158.214Z" fill="#5412A0" />
          <path
            d="M186.008 158.727C185.752 158.727 185.492 158.719 185.224 158.704C176.883 158.219 164.748 154.166 164.235 153.994L164.673 152.692C164.797 152.734 177.148 156.858 185.303 157.333C192.984 157.779 195.222 150.881 195.314 150.587L196.627 150.99C196.602 151.069 194.16 158.727 186.008 158.727Z"
            fill="#5D80F6" />
          <path
            d="M166.493 159.208C165.77 159.208 165.045 159.141 164.319 158.998L164.587 157.65C173.689 159.453 183.683 147.229 183.784 147.106L184.853 147.968C184.455 148.46 175.678 159.208 166.493 159.208Z"
            fill="#F2BC36" />
          <path
            d="M189.992 166.652C186.144 166.652 182.367 165.794 179.731 163.205C172.624 156.225 164.101 156.243 164.017 156.249L163.998 154.876C164.368 154.874 173.162 154.829 180.693 162.226C187.694 169.103 204.668 162.153 204.838 162.082L205.367 163.35C204.888 163.549 197.311 166.652 189.992 166.652Z"
            fill="#5412A0" />
          <path
            d="M194.217 169.344C194.179 169.305 190.239 165.294 181.954 160.964C173.894 156.754 163.553 162.647 163.448 162.708L162.759 161.521C163.208 161.26 173.852 155.183 182.59 159.748C191.093 164.191 195.04 168.22 195.204 168.389L194.217 169.344Z"
            fill="#A2EA98" />
          <path
            d="M232.483 168.618C232.749 168.618 233.019 168.61 233.295 168.594C241.938 168.091 254.511 163.892 255.042 163.713L254.588 162.365C254.46 162.408 241.663 166.682 233.212 167.174C225.254 167.636 222.935 160.488 222.84 160.184L221.48 160.601C221.506 160.684 224.036 168.618 232.483 168.618Z"
            fill="#5D80F6" />
          <path
            d="M252.701 169.119C253.451 169.119 254.203 169.05 254.953 168.901L254.676 167.505C245.245 169.372 234.889 156.708 234.786 156.579L233.679 157.473C234.09 157.983 243.185 169.119 252.701 169.119Z"
            fill="#F2BC36" />
          <path
            d="M228.355 176.829C232.343 176.829 236.255 175.94 238.987 173.258C246.351 166.026 255.181 166.044 255.269 166.051L255.289 164.628C254.906 164.625 245.794 164.579 237.99 172.243C230.736 179.368 213.149 172.168 212.972 172.093L212.425 173.407C212.921 173.614 220.772 176.829 228.355 176.829Z"
            fill="#5412A0" />
          <path
            d="M223.978 179.622C224.017 179.581 228.099 175.426 236.684 170.94C245.034 166.577 255.75 172.684 255.858 172.746L256.573 171.515C256.108 171.244 245.08 164.948 236.025 169.678C227.215 174.282 223.125 178.457 222.956 178.632L223.978 179.622Z"
            fill="#A2EA98" />
          <path
            d="M245.285 159.56L246.435 160.498L244.756 162.458L251.264 166.691L246.016 169.386L244.336 172.109L246.75 173.724L246.226 175.481L245.133 176.734C328.507 174.894 411.187 191.395 412 191.552V173.468C411.171 173.308 330.238 157.753 245.285 159.56Z"
            fill="#71C4F2" />
          <path
            d="M166.607 165.826L168.073 164.068L165.085 162.021L167.657 157.77L166.502 154.621L168.326 153.584L168.329 153.434L165.085 150.998L168.091 148.794C82.6369 146.788 0.833613 162.513 0 162.674V180.757C0.812327 180.601 83.349 164.129 166.65 165.935L166.607 165.826Z"
            fill="#71C4F2" />
          <path
            d="M79.1336 70.0936L112.297 111.192L139.153 134.386L144.037 148.424L145.054 179.96L139.56 190.133L129.795 195.016L126.946 192.371L131.625 179.96L133.457 165.515L127.149 176.298L120.639 177.926L116.366 173.933L112.094 177.926L105.787 175.281L102.938 178.536L96.8345 176.298L83.6093 146.594L80.1506 126.045L33.9269 98.5134L79.1336 70.0936Z"
            fill="#FFD55F" />
          <path
            d="M139.56 190.134L132.643 189.117L137.068 180.115L133.457 165.516L131.625 179.961L126.946 192.372L129.795 195.017L139.56 190.134Z"
            fill="#FF5631" />
          <path d="M132.049 167.925L119.27 176.647L120.639 177.927L127.15 176.299L132.049 167.925Z" fill="#FF5631" />
          <path d="M116.366 173.933L108.066 176.237L112.094 177.925L116.366 173.933Z" fill="#FF5631" />
          <path d="M105.787 175.282L98.4993 176.91L102.939 178.537L105.787 175.282Z" fill="#FF5631" />
          <path d="M83.6095 146.593V122.789L48.4769 89.3658L33.9271 98.5129L80.1508 126.044L83.6095 146.593Z"
                fill="#FF5631" />
          <path d="M112.297 111.192L136.509 136.217L144.213 153.906L144.037 148.424L139.153 134.386L112.297 111.192Z"
                fill="#FFAC5F" />
          <path d="M95.332 172.923L92.0532 155.239L83.6093 146.592L95.332 172.923Z" fill="#FFAC5F" />
          <path
            d="M390.398 115.677L311.454 124.881L283.665 136.689L260.482 179.908L264.859 181.59L270.328 179.155L269.41 188.215L279.454 190.024L283.255 185.081L283.107 191.405L287.861 193.175L294.44 191.66L296.926 186.676L298.167 189.879L304.777 192.214L310.481 188.774L312.489 183.28L314.467 187.582L324.912 187.102L340.132 160.442L388.509 165.669L390.398 115.677Z"
            fill="#FFD55F" />
          <path
            d="M268.091 180.149L277.194 170.004L283.339 168.223L290.246 157.909L292.681 156.555L297.204 160.458L302.43 158.495L296.467 163.333L284.885 187.462L283.175 188.468L283.251 185.094L286.019 177.194L282.164 172.984L277.835 173.366L270.328 179.154L268.091 180.149Z"
            fill="#FF5631" />
          <path
            d="M306.2 159.374L313.288 157.382L315.601 159.113L311.362 160.245L305.794 173.057L297.424 187.961L296.925 186.78L304.718 172.276L307.906 162.288L306.2 159.374Z"
            fill="#FF5631" />
          <path
            d="M316.786 160.847L323.466 159.753L318.386 165.922L316.935 173.996L313.022 184.464L312.489 183.279L315.773 173.726L317.251 165.392L316.786 160.847Z"
            fill="#FF5631" />
          <path
            d="M327.301 161.772L325.47 176.316L323.928 180.66L326.089 178.778L328.358 180.698L327.402 174.845L328.592 164.193L327.301 161.772Z"
            fill="#FF5631" />
          <path d="M340.132 160.442L341.975 156.444L389.014 152.285L388.509 165.669L340.132 160.442Z" fill="#FF5631" />
          <path d="M264.988 171.509L267.249 172.889L264.859 181.591L260.482 179.909L264.988 171.509Z" fill="#FFAC5F" />
          <path d="M282.164 172.984L276.429 177.411L280.326 183.287L284.94 180.271L286.018 177.194L282.164 172.984Z"
                fill="#FFAC5F" />
          <path
            d="M328.936 180.055L328.242 179.991L327.403 174.847L328.593 164.195L327.667 162.46C327.667 162.46 337.07 163.299 339.312 163.519C341.553 163.74 328.936 180.055 328.936 180.055Z"
            fill="#F2BC36" />
          <path
            d="M283.453 174.392L291.047 160.499L297.187 161.129L296.468 163.334L290.32 176.143L284.783 175.845L283.453 174.392Z"
            fill="#F2BC36" />
          <path d="M144.72 148.663L147.345 165.825H144.597L144.044 148.671L144.72 148.663Z" fill="#5412A0" />
          <path
            d="M83.6087 151.725L85.3666 161.07C85.6035 162.329 85.5108 163.629 85.0974 164.841L83.6094 169.208L93.3038 168.369L85.8108 151.538L83.6087 151.725Z"
            fill="#5412A0" />
          <path
            d="M291.047 160.499L283.453 174.392L284.783 175.845L290.32 176.143L291.361 173.988L288.474 171.237L293.109 160.711L291.047 160.499Z"
            fill="#E87F31" />
          <path
            d="M327.667 162.46L328.593 164.195L327.403 174.847L328.242 179.991L330.171 180.17L329.897 173.936L335.487 163.15L327.667 162.46Z"
            fill="#E87F31" />
          <path d="M31.5647 100.246L81.6343 68.4405L70.1402 51.7504L17.0588 88.4864L31.5647 100.246Z" fill="#68CA87" />
          <path d="M80.8246 72.1892L51.6406 102.371L45.7085 91.2607L79.1341 70.0935L80.8246 72.1892Z" fill="#E1F4E7" />
          <path d="M390.398 109.378L388.351 168.319L409.45 172.108L412 109.378H390.398Z" fill="#68CA87" />
          <path d="M386.771 116.1L380.793 154.962L388.822 154.753L390.398 115.677L386.771 116.1Z" fill="#5412A0" />
        </g>
        <defs>
          <clipPath id="clip0_1375_4134">
            <rect width="412" height="274.667" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}

export default memo(SeverErrorIllustration);
