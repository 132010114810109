import React from "react";

import { SvgIcon } from '@mui/material';

const MaticCoinIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "inherit",
      ...props.style,
    }}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    viewBox="0 0 100 100"
  >
    <g>
      <path d="M50,0A50,50,0,1,1,0,50,50,50,0,0,1,50,0Z" fill="#8f5ae8"/>
      <path fill="#fff" d="M67.10833,38.47081a4.49165,4.49165,0,0,0-4.23333,0l-9.54167,5.64166-6.66666,3.70417L37.125,53.46248a4.49171,4.49171,0,0,1-4.23333,0L25.30833,49.05a4.23335,4.23335,0,0,1-2.11666-3.52917v-8.8125a3.87917,3.87917,0,0,1,2.11666-3.52917l7.58334-4.23333a4.49165,4.49165,0,0,1,4.23333,0l7.58333,4.23333A4.23332,4.23332,0,0,1,46.825,36.70831V42.35l6.50833-3.87916V33.00414A3.87917,3.87917,0,0,0,51.25,29.475L37.3,21.36247a4.49171,4.49171,0,0,0-4.23333,0L18.95833,29.475a3.59584,3.59584,0,0,0-2.29166,3.52917v16.4a3.87916,3.87916,0,0,0,2.11666,3.5125l14.10834,8.12917a4.49165,4.49165,0,0,0,4.23333,0l9.54167-5.46667L53.19167,51.7,62.7,46.25a4.49171,4.49171,0,0,1,4.23333,0l7.58334,4.23334a4.23332,4.23332,0,0,1,2.11666,3.52916v8.64167a3.87915,3.87915,0,0,1-2.11666,3.52916l-7.58334,4.40834a4.49165,4.49165,0,0,1-4.23333,0L55.11667,66.3583A4.23335,4.23335,0,0,1,53,62.82914v-5.6625l-6.33333,3.7v5.64167a3.87917,3.87917,0,0,0,2.11666,3.52917L62.89167,78.15a4.49165,4.49165,0,0,0,4.23333,0l14.10833-8.1125a4.23333,4.23333,0,0,0,2.1-3.525V50.10831a3.87915,3.87915,0,0,0-2.11666-3.52917Z"/>
    </g>
  </SvgIcon>
);

export default MaticCoinIcon;
