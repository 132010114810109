// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  overview: icon('ic_chart'),
  payments: icon('ic_payments'),
  invoices: icon('ic_invoices'),
  user: icon('ic_user'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Overview',
        path: PATH_DASHBOARD.overview.root,
        icon: ICONS.overview,
      },
      {
        title: 'Payments',
        path: PATH_DASHBOARD.payments.root,
        icon: ICONS.payments,
      },
      {
        title: 'Invoices',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoices,
        disabled: true,
        // info: <Label color="info">+8</Label>,
      },
      {
        title: 'Bills',
        path: PATH_DASHBOARD.bills.root,
        icon: ICONS.invoices,
        // info: <Label color="info">+8</Label>,
      },
      {
        title: 'Recipients',
        path: PATH_DASHBOARD.recipients.root,
        icon: ICONS.user,
        // info: <Label color="info">+8</Label>,
      },
      // {
      //   title: 'Payers',
      //   path: PATH_DASHBOARD.inbox,
      //   icon: ICONS.user,
      //   // info: <Label color="info">+8</Label>,
      // },
    ],
  },
  // {
  //   subheader: 'Management',
  //   items: [
  //     // {
  //     //   title: 'payers',
  //     //   path: PATH_DASHBOARD.payers.root,
  //     //   icon: ICONS.user,
  //     //   // info: <Label color="info">+8</Label>,
  //     // },
  //
  //   ],
  // },
  // {
  //   subheader: 'Profile',
  //   items: [
  //     {
  //       title: 'My Invoices',
  //       path: PATH_DASHBOARD.bills.root,
  //       icon: ICONS.invoice,
  //       // info: <Label color="success">+12</Label>,
  //     },
  //     {
  //       title: 'Wallets',
  //       path: PATH_DASHBOARD.wallets.root,
  //       icon: ICONS.banking,
  //       // info: <Label color="success">+12</Label>,
  //     },
  //     // {
  //     //   title: 'Billing',
  //     //   path: PATH_DASHBOARD.billing.root,
  //     //   icon: ICONS.invoice,
  //     //   info: <Label color="error">+23</Label>,
  //     // },
  //   ],
  // },
];

export default navConfig;
