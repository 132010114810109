import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  isWhite?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, isWhite = false, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_MAIN = theme.palette.primary.main;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        {isWhite ? (
          <svg width="31" height="42" viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0583 0.00732416L6.83952 0.000366211C3.06207 0.000366211 -0.000163455 3.01137 -0.000163455 6.72562V35.2733C-0.000163455 31.5591 3.06207 28.5481 6.83952 28.5481L20.0563 28.555C28.0431 28.555 30.9998 21.2399 30.9998 14.2822C30.9998 7.3244 28.0451 0.0745769 20.0583 0.00732416Z" fill="url(#paint0_linear_8_598)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.84053 28.5481C3.06311 28.5481 0.000927321 31.5597 0.000927321 35.2746C0.000927321 38.9896 3.06311 42.0011 6.84053 42.0012H11.4678V28.5481H6.84053Z" fill="url(#paint1_linear_8_598)"/>
            <defs>
              <linearGradient id="paint0_linear_8_598" x1="6.2" y1="-4.4089" x2="21.1919" y2="52.9768" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0.4"/>
              </linearGradient>
              <linearGradient id="paint1_linear_8_598" x1="2.29536" y1="26.8676" x2="8.16512" y2="48.6629" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0.4"/>
              </linearGradient>
            </defs>
          </svg>
        ) : (
          <svg width="100%" height="100%" viewBox="0 0 500 683" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M323.53 0.113893L110.32 0.000768838C81.1708 -0.108121 53.1721 11.3534 32.4832 31.864C11.7943 52.3746 0.109738 80.2542 0 109.37V573.624C0.109738 544.508 11.7943 516.629 32.4832 496.118C53.1721 475.608 81.1708 464.146 110.32 464.255L323.498 464.368C452.319 464.368 500 345.405 500 232.256C500 119.107 452.344 1.21736 323.53 0.113893Z" fill="url(#paint0_linear_778_71)"/>
            <path d="M110.351 464.253C81.2015 464.144 53.2025 475.607 32.5136 496.118C11.8246 516.629 0.140424 544.51 0.03125 573.626C0.140424 602.742 11.8246 630.622 32.5136 651.134C53.2025 671.645 81.2015 683.108 110.351 682.999H184.986V464.253H110.351Z" fill="url(#paint1_linear_778_71)"/>
            <defs>
              <linearGradient id="paint0_linear_778_71" x1="0" y1="286.812" x2="500" y2="286.812" gradientUnits="userSpaceOnUse">
                <stop stopColor={PRIMARY_MAIN} stopOpacity="0.302"/>
                <stop offset="0.15" stopColor={PRIMARY_MAIN} stopOpacity="0.49"/>
                <stop offset="0.32" stopColor={PRIMARY_MAIN} stopOpacity="0.678"/>
                <stop offset="0.49" stopColor={PRIMARY_MAIN} stopOpacity="0.82"/>
                <stop offset="0.66" stopColor={PRIMARY_MAIN} stopOpacity="0.922"/>
                <stop offset="0.83" stopColor={PRIMARY_MAIN} stopOpacity="0.98"/>
                <stop offset="1" stopColor={PRIMARY_MAIN}/>
              </linearGradient>
              <linearGradient id="paint1_linear_778_71" x1="49.9691" y1="510.189" x2="208.201" y2="601.501" gradientUnits="userSpaceOnUse">
                <stop stopColor={PRIMARY_MAIN}/>
                <stop offset="0.2" stopColor={PRIMARY_MAIN} stopOpacity="0.812"/>
                <stop offset="0.43" stopColor={PRIMARY_MAIN} stopOpacity="0.631"/>
                <stop offset="0.65" stopColor={PRIMARY_MAIN} stopOpacity="0.502"/>
                <stop offset="0.84" stopColor={PRIMARY_MAIN} stopOpacity="0.431"/>
                <stop offset="1" stopColor={PRIMARY_MAIN} stopOpacity="0.4"/>
              </linearGradient>
            </defs>
          </svg>
        )}
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
