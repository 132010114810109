import React from "react";

import { SvgIcon } from '@mui/material';

const BinancecoinIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#FCD535",
      ...props.style,
    }}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    viewBox="0 0 235 235"
  >
    <circle fill="currentColor" cx="117.5" cy="117.5" r="117.5" />
    <path d="M119.061 40.4833L117.802 39.7485L116.542 40.4833L83.6381 59.6771L74.0639 54.0922L117.26 28.8943L160.998 54.4079L151.965 59.6771L119.061 40.4833ZM116.542 99.5282L117.802 100.263L119.061 99.5282L151.965 80.3344L166.021 88.5336V106.373L132.732 125.792L131.492 126.515V127.951L131.492 164.976L117.802 172.962L104.111 164.976L104.111 127.951V126.515L102.871 125.792L69.5824 106.373L69.5824 88.5336L83.6381 80.3344L116.542 99.5282ZM53.078 77.5039L51.8377 78.2274V79.6633L51.8377 96.0221L42.5 90.5751L42.5 72.5044L56.3576 64.4208L65.9319 70.0058L53.078 77.5039ZM51.8377 153.704V155.14L53.078 155.864L86.3667 175.282V185.358L42.5 159.77L42.5 111.232L51.8377 116.679L51.8377 153.704ZM116.542 192.884L117.802 193.619L119.061 192.884L131.492 185.633V195.078L117.26 203.38L104.111 195.709V185.633L116.542 192.884ZM182.525 155.864L183.765 155.14V153.704L183.765 116.679L192.021 111.864V159.77L149.236 184.727V175.282L182.525 155.864ZM183.765 96.0221V79.6633V78.2274L182.525 77.5039L169.671 70.0058L178.704 64.7365L192.021 72.5044V91.2066L183.765 96.0221ZM101.344 70.0058L117.802 60.4058L134.259 70.0058L117.802 79.6058L101.344 70.0058ZM69.5824 144.834L69.5824 127.03L86.3667 136.821V154.625L69.5824 144.834ZM149.236 154.625V136.821L166.021 127.03V144.834L149.236 154.625Z" fill="white" stroke="white" strokeWidth="5"/>
  </SvgIcon>
);

export default BinancecoinIcon;
