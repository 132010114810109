import React, { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';
import QRCodePage from "../pages/auth/QRCodePage";
import GetStartedPage from "../sections/GetStartedPage";

import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <QRCodePage />;
  }

  if(user?.isNew && isAuthenticated){
    return <GetStartedPage />
  }

  if (isAuthenticated && user?.payerStatus === 'PENDING' && pathname !== PATH_DASHBOARD.becomePayer) {
    return <Navigate to={PATH_DASHBOARD.becomePayer} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (isAuthenticated && !user?.enabled && pathname !== PATH_DASHBOARD.error.lockedUser) {
    return <Navigate to={PATH_DASHBOARD.error.lockedUser} />;
  }

  return <>{children}</>;
}
