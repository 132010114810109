import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

import InactivityDialog from '../../components/inactivity-dialog';

const InactivityForm = () => {
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
    resetLogoutTimer();
  };

  const logoutTimer = useRef<NodeJS.Timeout | null>(null);

  const resetLogoutTimer = useCallback(() => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }

    logoutTimer.current = setTimeout(() => {
      setOpenLogoutDialog(true);
    }, 1800000); // 5 min of inactivity
  }, []);

  useEffect(() => {
    if (!openLogoutDialog) {
      const events = ['mousemove', 'keydown', 'click', 'scroll'];

      events.forEach(event => {
        window.addEventListener(event, resetLogoutTimer);
      });

      resetLogoutTimer();

      return () => {
        events.forEach(event => {
          window.removeEventListener(event, resetLogoutTimer);
        });
        if (logoutTimer.current) {
          clearTimeout(logoutTimer.current);
        }
      };
    }
    // eslint-disable-next-line
  }, [resetLogoutTimer]);

  return <Box>
    {openLogoutDialog ? (
      <InactivityDialog
        inactiveTime={Date.now() + 300000}
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        resetTimer={resetLogoutTimer}
      />
    ) : null}
  </Box>
}

export default InactivityForm;