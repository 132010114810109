import React from "react";

import { SvgIcon } from '@mui/material';

const EtheriumIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#BEA4F2",
      ...props.style,
    }}
    viewBox="0 0 73 73"
  >
    <path
      d="M66.9998 36.5C66.9998 19.6553 53.3444 6 36.4998 6C19.6551 6 5.99976 19.6553 5.99976 36.5C5.99976 53.3447 19.6551 67 36.4998 67C53.3444 67 66.9998 53.3447 66.9998 36.5Z"
      fill="#8A4FFA"
    />
    <path
      d="M70 36.5C70 17.9985 55.0015 3 36.5 3C17.9985 3 3 17.9985 3 36.5C3 55.0015 17.9985 70 36.5 70C55.0015 70 70 55.0015 70 36.5Z"
      stroke="white"
      strokeWidth="6"
    />
    <path
      d="M36.097 41.8275L27 37.5737L36.097 53.2002L45.1939 37.5737L36.097 41.8275Z"
      fill="white"
    />
    <path
      d="M44.2194 33.8395L36.0966 20.0001L27.9646 33.855L36.0966 30.0713L44.2194 33.8395Z"
      fill="white"
    />
    <path
      d="M28.6553 35.9204L36.1016 39.3721L43.548 35.9157L36.1016 32.4656L28.6553 35.9204Z"
      fill="white"
    />
  </SvgIcon>
);

export default EtheriumIcon;
