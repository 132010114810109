import React from "react";

import { SvgIcon } from '@mui/material';

const TetherIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#009393",
      enableBackground: 'new 0 0 2500 2500',
      ...props.style,
    }}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 2500 2500"
  >
    <g id="Layer_x0020_1">
      <rect
        y="0"
        fill="none"
        width="2500"
        height="2500"
      />
      <path
        className="st1"
        fillRule='evenodd'
        clipRule='evenodd'
        d="M540.6,213.7h1444.7c36.2,0,67.3,20.7,82.8,46.6l419.4,730.1c20.7,36.2,15.5,88-15.5,119.1L1317.3,2259.1   c-36.2,36.2-98.4,36.2-134.6,0L28,1114.7c-31.1-31.1-36.2-82.8-15.5-119.1l450.5-735.3C478.5,229.3,509.5,213.7,540.6,213.7   L540.6,213.7z M1798.9,540v207.1h-409.1v139.8c290,15.5,507.4,77.7,507.4,150.2v155.3c0,72.5-217.5,139.8-507.4,150.2v352.1h-274.4   v-341.8c-290-15.5-507.5-77.7-507.5-150.2v-155.3c0-72.5,217.5-139.8,507.5-150.2v-145H706.3V545.1h1092.6L1798.9,540L1798.9,540z    M1255.2,1244.2c310.7,0,564.4-51.8,631.7-124.3c-51.8-62.1-248.6-108.7-491.9-119.1V1151c-46.6,0-88,5.2-134.6,5.2   s-93.2,0-134.6-5.2v-150.2c-243.4,10.4-440.1,62.1-491.9,119.1C685.6,1192.4,944.5,1244.2,1255.2,1244.2L1255.2,1244.2z"
      />
    </g>
  </SvgIcon>
);

export default TetherIcon;
