import React from 'react';
import { CSVLink } from 'react-csv';
// @mui
import { TableRow, TableCell, Typography, Stack } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
// types
import { InstructionProps } from './types';
// utils
import { generateExamplePaymentsCSV, generateExampleRecipientCSV } from '../../utils/generateJSONtoCSV';
// constants
import { CSVPaymentsExampleHeaders, CSVRecipientsExampleHeaders } from '../../constants/CSVHeaders';

// ----------------------------------------------------------------------

export default function TableInstruction({
  content,
  type,
  isNotFound,
  action
}: InstructionProps) {

  const theme = useTheme()

  return (
    <TableRow>
      {isNotFound && (
        <TableCell colSpan={12}>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{
              marginY: 5,
              marginX: 'auto',
              width: 1,
              maxWidth: '800px',
              borderStyle: 'dashed',
              borderColor: theme.palette.primary.main,
              borderRadius: 2,
              paddingX: 7.75,
              paddingY: 7.125
          }}
          >
            <Stack
              sx={{
                background: alpha(theme.palette.grey['200'], .25),
                borderRadius: 2,
                padding: 3.75,
                textAlign: 'center'
              }}
            >
              {action && action}
              {content && content}
              {type && (
                <CSVLink
                  data={type === "payment" ? generateExamplePaymentsCSV() : generateExampleRecipientCSV()}
                  headers={type === "payment" ? CSVPaymentsExampleHeaders : CSVRecipientsExampleHeaders}
                  filename={`Example-file-for-import`}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography variant="subtitle1" color={theme.palette.primary.main} sx={{ textDecoration: "none" }}>Download example file</Typography>
                </CSVLink>
            )}
            </Stack>
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
}
