import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import tokenReducer from './slices/token';
import transactionsReducer from './slices/transactions';
import walletReducer from './slices/wallet';
import payersReducer from './slices/payers';
import recipientsReducer from './slices/recipients';
import invoiceReducer from './slices/invoices';
import balancesReducer from './slices/balances';
import adminReducer from './slices/admin';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  wallet: walletReducer,
  token: tokenReducer,
  transaction: transactionsReducer,
  payers: payersReducer,
  recipients: recipientsReducer,
  invoices: invoiceReducer,
  balances: balancesReducer,
  admin: adminReducer,
});

export { rootPersistConfig, rootReducer };
