import React from "react";
import { useSnackbar } from '../snackbar';
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { Stack, Tooltip, Typography } from "@mui/material";
import { IconButtonAnimate } from "../animate";
import { CopyIcon } from '../../assets/icons';

type Props = {
  text: string;
  start?: number;
  end?: number;
  size?: number;
  variant?: "body2" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "overline" | undefined;
};

const CopyText = ({ text, start = 10, end = 10, variant = "body2" }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { copy } = useCopyToClipboard();

  const onCopy = () => {
    if (text) {
      enqueueSnackbar('Copied!');
      copy(text);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
    >
      {start + end > text.length
        ? <Typography variant={variant}>{text}</Typography>
        : <Typography variant={variant}>
          {start > 0 && text ? text.slice(0, start) : ""}
          ...
          {end > 0 && text
            ? text.slice(
              text.length - end,
              text.length
            )
            : ""}
        </Typography>
      }
      <Tooltip title="Copy">
        <IconButtonAnimate sx={{ width: 30 }} onClick={onCopy} size="small">
          <CopyIcon width={16} style={{ margin: 0 }} />
        </IconButtonAnimate>
      </Tooltip>
    </Stack>
  )
}

export default CopyText;
