import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';


// ----------------------------------------------------------------------

function PageNotFoundIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other} sx={{ minWidth: { md: '504px' }, height: { md: 'auto' } }}>
      <svg width="100%" height="100%" viewBox="0 0 504 336" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M247.313 86.3049C228.958 68.7053 207.547 58.5908 189.879 57.5509L180.3 48.3663L162.926 66.486C146.069 84.0655 156.522 121.441 186.271 149.967C216.02 178.492 253.802 187.365 270.658 169.786L288.033 151.666L278.454 142.482C276.673 124.872 265.668 103.905 247.313 86.3049Z"
          fill="#FFD55F" />
        <path
          d="M163.542 120.119C163.516 120.052 153.538 94.4765 160.281 78.3493C165.045 66.9581 175.504 55.2191 181.257 49.2861L180.298 48.3672L162.924 66.4868C151.585 78.3132 152.604 99.0973 163.542 120.119Z"
          fill="#FF5631" />
        <path
          d="M245.775 157.582C245.775 157.582 250.345 165.997 239.776 169.633C229.207 173.269 195.631 158.201 195.627 158.198C223.523 180.093 255.481 185.614 270.603 169.844L287.977 151.724L285.84 149.675L245.775 157.582Z"
          fill="#FFAC5F" />
        <path
          d="M288.032 151.667C304.888 134.087 294.437 96.7118 264.688 68.1863C234.938 39.6608 197.157 30.7874 180.301 48.3669C163.444 65.9465 173.896 103.322 203.645 131.848C233.394 160.373 271.176 169.246 288.032 151.667Z"
          fill="#E1F4E7" />
        <path
          d="M279.03 153.258C291.855 139.882 283.903 111.445 261.268 89.7404C238.632 68.0363 209.886 61.2849 197.06 74.6607C184.235 88.0365 192.187 116.474 214.822 138.178C237.457 159.883 266.204 166.634 279.03 153.258Z"
          fill="#68CA87" />
        <path
          d="M180.3 48.3664C177.282 51.5147 175.143 55.2997 173.831 59.5527C193.058 52.7193 222.891 62.8875 247.313 86.305C271.735 109.722 283.147 139.101 277.128 158.599C281.322 157.109 285.014 154.813 288.033 151.665C304.889 134.086 294.437 96.7101 264.687 68.1845C234.938 39.6589 197.157 30.7869 180.3 48.3664Z"
          fill="#FFAC5F" />
        <path
          d="M195.201 62.6539C195.201 62.6539 203.26 63.6863 215.736 69.5512C228.212 75.4152 242.349 88.5763 242.349 88.5763C242.349 88.5763 227.81 77.4774 217.29 72.8557C206.771 68.2341 194.978 66.1719 194.978 66.1719L195.201 62.6539Z"
          fill="white" />
        <path d="M193.119 65.373L193.484 62.0508L189.313 61.1343L188.814 64.4591L193.119 65.373Z" fill="white" />
        <path d="M187.688 61.0512L185.474 60.7967L185.379 63.4603L187.053 63.4914L187.688 61.0512Z" fill="white" />
        <path d="M288.032 151.666L270.038 170.432L281.427 181.353L299.421 162.586L288.032 151.666Z" fill="#FFAC5F" />
        <path
          d="M349.447 207.695L337.651 195.024L307.529 164.51L312.567 151.991L285.46 157.76L271.737 181.353L271.072 181.76L280.915 179.903L290.073 187.287L303.665 198.485L337.175 235.532L350.178 231.927L359.232 218.864L349.447 207.695Z"
          fill="#FFAC5F" />
        <path
          d="M139.934 98.0439H126.33V84.441H119.226V98.0439H105.623V105.148H119.226V118.752H126.33V105.148H139.934V98.0439Z"
          fill="#68CA87" />
        <path
          d="M409.865 219.069L349.032 174.366L318.442 150.741L285.46 157.76L270.343 183.748L277.095 186.799L287.168 180.907L291.816 171.255L302.304 170.974L319.718 191.905L337.651 195.024L349.447 207.694L359.232 218.864L351.806 229.58L370.374 257.769L409.865 219.069Z"
          fill="#FFD55F" />
        <path
          d="M288.94 181.976L290.073 187.287L302.994 192.258L303.665 198.485L334.685 199.757L339.583 206.871L328.969 209.735L330.343 218.802L326.751 220.302L330.973 226.372L312.225 232.271L307.766 229.673L306.718 223.121L294.857 217.226L295.229 210.322L281.245 209.864L281.866 196.498L277.509 192.411L277.095 186.799L287.168 180.907L288.94 181.976Z"
          fill="#FFD55F" />
        <path d="M288.94 181.976L277.509 192.411L277.095 186.799L287.168 180.907L288.94 181.976Z" fill="#FF5631" />
        <path d="M281.866 196.498L297.584 200.662L281.663 200.867L281.866 196.498Z" fill="#FF5631" />
        <path d="M295.229 210.322L327.846 207.914L334.148 208.338L328.968 209.735L294.857 217.226L295.229 210.322Z"
              fill="#FF5631" />
        <path
          d="M306.718 223.121L319.489 222.248L326.751 220.302L327.492 221.367L315.223 225.596L307.349 227.063L306.718 223.121Z"
          fill="#FF5631" />
        <path d="M302.304 170.974L316.787 171.315L308.166 173.468L313.239 184.117L302.304 170.974Z" fill="#FF5631" />
        <path d="M337.65 195.024L357.969 207.903L349.447 207.694L337.65 195.024Z" fill="#FF5631" />
        <path d="M273.87 185.342L279.84 179.085L275.518 174.852L270.343 183.748L273.87 185.342Z" fill="#FFAC5F" />
        <path d="M301.569 193.053L302.297 199.418L296.032 199.398L295.279 194.153L301.569 193.053Z" fill="#FFAC5F" />
        <path d="M334.688 200.745L326.648 201.947L328.165 207.141L333.843 207.523L338.739 206.202L334.688 200.745Z"
              fill="#FFAC5F" />
        <path d="M328.419 210.729L329.191 217.198L322.525 219.415L320.644 213.755L328.419 210.729Z" fill="#FFAC5F" />
        <path d="M327.492 221.367L321.989 225.061L324.153 228.518L330.973 226.372L327.492 221.367Z" fill="#FFAC5F" />
        <path
          d="M304.321 172.631L292.829 174.376L287.908 181.353L287.168 180.907L291.816 171.255L303.643 171.005L304.321 172.631Z"
          fill="#FF5631" />
        <path d="M319.717 191.905L341.07 200.577L349.447 207.694L337.65 195.024L319.717 191.905Z" fill="#FF5631" />
        <path d="M302.994 192.258L305.626 198.566L303.665 198.485L302.994 192.258Z" fill="#FF5631" />
        <path d="M338.524 207.157L338.855 207.95L329.302 211.938L328.968 209.735L334.148 208.338L338.524 207.157Z"
              fill="#FF5631" />
        <path d="M329.703 219.069L329.927 219.609L327.393 221.128L326.751 220.302L329.703 219.069Z" fill="#FF5631" />
        <path d="M330.973 226.372L329.851 227.434L329.352 226.882L330.973 226.372Z" fill="#FF5631" />
        <path d="M365.22 260.951L411.773 215.072L431.307 227.434L381.286 279.754L365.22 260.951Z" fill="#68CA87" />
        <path
          d="M407.618 217.419L375.522 236.229C373.468 237.433 370.85 237.021 369.265 235.244L361.99 227.09C360.749 225.699 360.386 223.732 361.047 221.988L361.446 220.937C361.886 219.776 361.693 218.47 360.935 217.486L352.54 206.588L349.446 207.693L359.231 218.862L351.805 229.578L355.725 235.53L365.967 251.079L369.615 256.617L408.634 218.164L407.618 217.419Z"
          fill="#FF5631" />
        <path
          d="M127.464 144.927H110.855V148.724C98.1001 154.9 89.3027 167.966 89.3027 183.089C89.3027 194.539 100.566 219.039 110.855 239.031V269.459H127.464C127.464 269.459 165.626 204.165 165.626 183.089C165.625 162.012 148.54 144.927 127.464 144.927Z"
          fill="#FF5631" />
        <path
          d="M149.016 183.089C149.016 204.165 110.854 269.459 110.854 269.459C110.854 269.459 72.6919 204.165 72.6919 183.089C72.6919 162.013 89.7775 144.927 110.854 144.927C131.93 144.927 149.016 162.012 149.016 183.089Z"
          fill="#FFD55F" />
        <path
          d="M110.854 201.649C122.757 201.649 132.406 192 132.406 180.097C132.406 168.194 122.757 158.545 110.854 158.545C98.9513 158.545 89.3022 168.194 89.3022 180.097C89.3022 192 98.9513 201.649 110.854 201.649Z"
          fill="white" />
        <path
          d="M104.363 180.097C104.363 170.843 110.196 162.954 118.385 159.9C116.041 159.025 113.504 158.545 110.855 158.545C98.9518 158.545 89.3027 168.194 89.3027 180.097C89.3027 192 98.9518 201.649 110.855 201.649C113.504 201.649 116.041 201.168 118.385 200.294C110.196 197.24 104.363 189.35 104.363 180.097Z"
          fill="#FF5631" />
        <path
          d="M118.385 297.764C136.696 297.764 151.541 295.189 151.541 292.012C151.541 288.835 136.696 286.259 118.385 286.259C100.073 286.259 85.228 288.835 85.228 292.012C85.228 295.189 100.073 297.764 118.385 297.764Z"
          fill="#FF5631" />
        <path
          d="M377.883 153.137H369.673V144.927H365.386V153.137H357.176V157.424H365.386V165.635H369.673V157.424H377.883V153.137Z"
          fill="#68CA87" />
        <path
          d="M189.537 186.282H183.353V180.097H180.123V186.282H173.938V189.512H180.123V195.696H183.353V189.512H189.537V186.282Z"
          fill="#68CA87" />
        <path
          d="M111.643 124.936H105.459V118.752H102.229V124.936H96.0439V128.166H102.229V134.35H105.459V128.166H111.643V124.936Z"
          fill="#68CA87" />
      </svg>

    </Box>
  );
}

export default memo(PageNotFoundIllustration);
