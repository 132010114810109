import React, { createContext, useEffect, useReducer, useCallback } from 'react';
// utils
import {
  axiosAuth,
  axiosWallet,
  axiosInvocer
} from '../utils/axios';
//
import { clearSessions, isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';
import qs from 'qs';
import axios from "axios";
import { HOST_API } from "../config";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  UPDATE = 'UPDATE_USER',
  BECOME_PAYER = 'BECOME_PAYER',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    accessToken: string | null;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    accessToken: string | null;
  };
  [Types.LOGOUT]: undefined;
  [Types.UPDATE]: {
    user: AuthUserType;
    accessToken: string | null;
  };
  [Types.BECOME_PAYER]: {
    user: AuthUserType
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  accessToken: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        accessToken: action.payload.accessToken
      };
    case Types.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        accessToken: action.payload.accessToken
      };
    case Types.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        accessToken: null,
      };
    case Types.UPDATE:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        accessToken: action.payload.accessToken
      };
    case Types.BECOME_PAYER:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...action.payload,
          isNew: false
        },
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      const refreshToken = sessionStorage.getItem('refreshToken') || '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);

        const user = await getUserInfo(accessToken);
        const referrer = await getUserReferrer(accessToken);
        if (!user?.walletId) {
          const spaces = await getUserSpaces(accessToken);
          !sessionStorage.getItem('spaceHash') && sessionStorage.setItem('spaceHash', Object.keys(spaces || {})[0])
        } else {
          sessionStorage.setItem('spaceHash', user?.walletId)
        }

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user: {
              ...user,
              // photoURL: photo || null,
              referrer: referrer ?? 'LINK----////-///---///',
            },
            accessToken
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
            accessToken: null
          },
        });
      }
    } catch (err) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
          accessToken: null
        },
      });
    }


  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (username: string, password: string) => {
    const options = {
      method: 'POST',
      data: qs.stringify({
        username,
        password,
        grant_type: "password",
        client_id: "account",
      }),
      url: '/auth/realms/mobile/protocol/openid-connect/token'
    }
    const response = await axiosAuth(options);

    const { access_token: accessToken, refresh_token: refreshToken } = response.data;

    if(accessToken) await loadInfo(accessToken, refreshToken);
  };

  const loadInfo = async (accessToken: string, refreshToken: string) => {
    setSession(accessToken, refreshToken);
    // const photo = await getUserPhoto(accessToken);
    // console.log(photo);
    const spaces = await getUserSpaces(accessToken);
    // console.log(spaces);
    const user = await getUserInfo(accessToken)
      .then((res) => ({
        ...res,
        // photoURL: photo,
        walletId: res.walletId ?? Object.keys(spaces || {})[0],
      }));

    !sessionStorage.getItem('spaceHash') && sessionStorage.setItem('spaceHash', user?.walletId ?? Object.keys(spaces || {})[0])
    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
        accessToken
      },
    });
  }

  const updateUserInfo = async (data: any) => {
    await axiosInvocer({
      method: "PUT",
      url: `/users`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
      },
      data: {
        ...data,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        address: data.address,
        walletId: data?.walletId,
        isNew: data?.isNew,
      }
    })
      .then((data) => dispatch({
        type: Types.UPDATE,
        payload: {
          user: data,
          accessToken: sessionStorage.getItem('accessToken') ?? null
        },
      }))
  }

  const becomePayer = async () => {
    await axiosInvocer({
      method: "POST",
      url: "/users/become-payer",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
      },
      data: {}
    })
      .then(({ data }) => dispatch({
        type: Types.BECOME_PAYER,
        payload: {
          user: data
        },
      }))
  };

  const getUserInfo = async (accessToken: string) => axiosInvocer({
    method: "GET",
    url: '/users/current',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((res) => res.data)

  const getUserReferrer = async (accessToken: string) => axiosInvocer({
    method: "GET",
    url: '/parties/referrer',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((res) => res.data)

  const getUserSpaces = async (accessToken: string) => axiosWallet({
      method: "GET",
      url: '/wallet/spaces',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then(
        (res) => res.data
      )

  // LOGOUT
  const logout = async () => {
    // try {
    //   const options = {
    //     method: 'POST',
    //     data: {},
    //     headers: {
    //       Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    //       client_id: "account",
    //       refresh_token: sessionStorage.getItem('refreshToken')
    //     },
    //     url: '/auth/realms/mobile/protocol/openid-connect/logout'
    //   }
    //   await axiosAuth(options);
    // } catch (e) {
    //   console.error(e);
    // } finally {
      clearSessions();
      dispatch({
        type: Types.LOGOUT,
      });
    // }
  };

  const getHashQR = async () => axios.post(`${HOST_API}/web/login_link`)
    .then(({ data }) => data.id)

  const loginQR = async (hash: string) => {
    await axios.get(`${HOST_API}/web/login_link/${hash}`, {
      timeout: 2000,
    })
      .then(async ({ data: { access_token: accessToken, refresh_token: refreshToken } }: any) => {
        if(accessToken) await loadInfo(accessToken, refreshToken);
      })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        getHashQR,
        loginQR,
        getUserReferrer,
        updateUserInfo,
        becomePayer
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
