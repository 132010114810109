import React from "react";

import { SvgIcon } from '@mui/material';

const LitecoinIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#9EA4B0",
      ...props.style,
    }}
    viewBox="0 0 73 73"
  >
    <path
      d="M66.9995 36.5C66.9995 19.6553 53.3442 6 36.4995 6C19.6548 6 5.99951 19.6553 5.99951 36.5C5.99951 53.3447 19.6548 67 36.4995 67C53.3442 67 66.9995 53.3447 66.9995 36.5Z"
      fill="#9EA4B0"
    />
    <path
      d="M70 36.5C70 17.9985 55.0015 3 36.5 3C17.9985 3 3 17.9985 3 36.5C3 55.0015 17.9985 70 36.5 70C55.0015 70 70 55.0015 70 36.5Z"
      stroke="white"
      strokeWidth="6"
    />
    <path
      d="M24.4063 50.0945H49.184V44.2763H33.2303L35.723 38.4579H46.2796V32.6396H38.2188L41.3671 25.2931L36.0191 23L31.889 32.6381H23V38.4564H29.3932L24.4063 50.0945Z"
      fill="white"
    />
  </SvgIcon>
);

export default LitecoinIcon;
