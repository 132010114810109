import React from 'react';
// @mui
import { Button, Stack, Typography } from '@mui/material';
// hooks
// layouts
import Iconify from "../components/iconify";
import GetStarted from "../layouts/getStarted";
import { useAuthContext } from "../auth/useAuthContext";
import { PATH_DASHBOARD } from '../routes/paths';
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
// import { PATH_DASHBOARD } from "../routes/paths";
//

export default function GetStartedPage() {
  const { user, becomePayer, updateUserInfo } = useAuthContext();
  const navigate = useNavigate();

  const openPayerAccount = () => {
    updateUserInfo({
      ...user,
      isNew: false
    }).then(() => {
      becomePayer().then(() => navigate(PATH_DASHBOARD.becomePayer))
    })
  };

  const openRecipientAccount = () => {
    updateUserInfo({
      ...user,
      isNew: false
    }).then(() => {
      navigate(PATH_DASHBOARD.bills.root);
    })
  }

  return (
    <GetStarted
      leftElement={(
        <Stack alignItems="center" justifyContent="center">
          <Typography
            variant="h2"
            textAlign="center"
            mb={4}
            color={(theme) => theme.palette.text.primary}
          >
            Enter as Recipient
          </Typography>
          <Typography
            variant="h4"
            textAlign="center"
            mb={10}
            color={(theme) => theme.palette.text.secondary}
          >
            Create & send invoices to get paid
          </Typography>
          <Button
            size="large"
            variant="outlined"
            onClick={openRecipientAccount}
            startIcon={<Iconify icon="lets-icons:user-box-duotone" width={30} />}
            sx={{
              py: 5,
              px: 10,
              fontSize: 20
            }}
          >
            Enter as Recipient
          </Button>
        </Stack>
      )}
      rightElement={(
        <Stack alignItems="center" justifyContent="center">
          <Typography
            variant="h2"
            textAlign="center"
            mb={4}
            color={(theme) => theme.palette.text.primary}
          >
            Open Payer Account
          </Typography>
          <Typography
            variant="h4"
            textAlign="center"
            mb={10}
            color={(theme) => theme.palette.text.secondary}
          >
            Pay incoming invoices & create outgoing transfers
          </Typography>
          <Button
            size="large"
            variant="outlined"
            startIcon={<Iconify icon="mdi:company" width={28} />}
            onClick={openPayerAccount}
            sx={{
              py: 5,
              px: 10,
              fontSize: 20
            }}
          >
            Open Payer Account
          </Button>
        </Stack>
      )}
    />
  );
}
