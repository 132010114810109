// @mui
import { Typography, Stack, Link } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children }: Props) {
  return (
    <StyledRoot>
      <Logo
        isWhite
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          textAlign="center"
          spacing={3}
          maxWidth={{
            sm: 500,
            xl: 800
          }}
          sx={{
            height: "100%"
          }}
        >
          <Typography variant="h3" sx={{ color: 'white' }}>
            <span style={{ fontWeight: 800 }}>1. Download</span> Payperless Wallet app
          </Typography>
          <Stack direction="row" spacing={3} sx={{
            sm: { pb: 1 },
            md: { pb: 2 },
            lg: { pb: 3 },
          }}>
            <Link href="https://apps.apple.com/us/app/payperless-wallet/id1552741313" target="_blank" rel="noopener">
              <Image
                alt="apple"
                src={'/assets/icons/apps/appstore-button.svg'}
                disabledEffect
                visibleByDefault
                sx={{ objectFit: 'contain!important' }}
              />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.payperless.wallet" target="_blank" rel="noopener">
              <Image
                alt="google"
                src={'/assets/icons/apps/google-play-button.svg'}
                disabledEffect
                visibleByDefault
                sx={{ objectFit: 'contain' }}
              />
            </Link>
          </Stack>
          <Typography variant="h3" sx={{
            color: 'white',
            sm: { pb: 1 },
            md: { pb: 2 },
            lg: { pb: 3 },
          }}>
            <span style={{ fontWeight: 800 }}>2. Create</span> your account
          </Typography>
          <Typography variant="h3" sx={{ color: 'white' }}>
            <span style={{ fontWeight: 800 }}>3. Open</span> the <span style={{ fontWeight: 800 }}>QR scanner</span> and
            scan the QR code on the right
          </Typography>
        </Stack>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={'/assets/illustrations/login-phone.png'}
          sx={{
            height: "100%"
          }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
