import React, { useEffect, useCallback, useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material';
import { useAuthContext } from '../../auth/useAuthContext';
import useCountdown from '../../hooks/useCountdown';

type InactivityDialogProps = {
  open: boolean;
  onClose: () => void;
  inactiveTime: number | null;
  resetTimer: () => void;
};

export default function InactivityDialog({ open, onClose, inactiveTime }: InactivityDialogProps) {
  const { logout } = useAuthContext();
  const countdownTimer = useRef<any>(null);
  const { minutes, seconds } = useCountdown(inactiveTime ? new Date(inactiveTime) : new Date(Date.now() + 300000));

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      handleClose();
    } catch (error) {
      console.error(error);
      handleClose();
    }
  // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    clearInterval(countdownTimer?.current);
    onClose()
  }

  useEffect(() => {
    if (open) {
      const countdownTimer = setInterval(() => {
        if (inactiveTime && (inactiveTime < Date.now())) {
          handleLogout()
        }
      }, 1000);

      return () => clearInterval(countdownTimer);
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2, textAlign: "center" }}>No activity</DialogTitle>
        <Typography variant="body1" sx={{ color: 'text.secondary', px: '25px', mb: '20px' }}>
        You've been inactive for some time. Continue session?
        </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        divider={<Box sx={{ mx: { xs: 1, sm: 2.5 } }}>:</Box>}
        sx={{ typography: 'h2' }}
      >
        <TimeBlock label="Minutes" value={minutes} />
        <TimeBlock label="Seconds" value={seconds} />
      </Stack>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={onClose}>
          Continue
        </Button>
        <Button variant="outlined" onClick={handleLogout}>Log out</Button>
      </DialogActions>
    </Dialog>
  );
}

  type TimeBlockProps = {
    label: string;
    value: string;
  };

function TimeBlock({ label, value }: TimeBlockProps) {
  return (
    <Box>
      <Box> {value} </Box>
      <Box sx={{ color: 'text.secondary', typography: 'body1' }}>{label}</Box>
    </Box>
  );
}
