import React from "react";

import { SvgIcon } from '@mui/material';

const EtheriumClassicIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#00BC59",
      ...props.style,
    }}
    viewBox="0 0 73 73"
  >
    <path
      d="M66.9995 36.5C66.9995 19.6553 53.3442 6 36.4995 6C19.6548 6 5.99951 19.6553 5.99951 36.5C5.99951 53.3447 19.6548 67 36.4995 67C53.3442 67 66.9995 53.3447 66.9995 36.5Z"
      fill="#00BC59"
    />
    <path
      d="M70 36.5C70 17.9985 55.0015 3 36.5 3C17.9985 3 3 17.9985 3 36.5C3 55.0015 17.9985 70 36.5 70C55.0015 70 70 55.0015 70 36.5Z"
      stroke="white"
      strokeWidth="6"
    />
    <path
      d="M36.097 41.8275L27 37.5737L36.097 53.2002L45.1939 37.5737L36.097 41.8275Z"
      fill="white"
    />
    <path
      d="M44.2196 33.8395L36.0969 20.0001L27.9648 33.855L36.0969 30.0713L44.2196 33.8395Z"
      fill="white"
    />
    <path
      d="M28.6553 35.9204L36.1016 39.3721L43.548 35.9157L36.1016 32.4656L28.6553 35.9204Z"
      fill="white"
    />
  </SvgIcon>
);

export default EtheriumClassicIcon;
