import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  // Auth
  LoginPage,
  QRCodePage,
  // Dashboard: User
  UserAccountPage,
  // Dashboard: Bill
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page400,
  Page500,
  Page403,
  Page423,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  //
  MyPayers,
  MyRecipient,
  BecomePayer,
  //
  Wallets,
  Billing,
  //
  MyPayersDetails,
  Parties,
  InvoiceInboxPage,
  MyRecipientDetails,
  ExternalPayersDetails,
  // Outbox
  PaymentPage,
  PaymentEditInvoice,
  PaymentDetailsInvoice,
  PaymentMassPayout,
  PaymentNewInvoice,
  MassRecipients,
  OverviewPage,
  PaymentQuickPayout,
  // admin
  AdminOverviewPage,
  AdminInvoicesPage,
} from './elements';

import GuestGuard from "../auth/GuestGuard";
import GetStartedPage from "../sections/GetStartedPage";
import OnboardingLayout from '../layouts/dashboard/OnboardingLayout';
import UploadCSVPage from '../pages/dashboard/payments/UploadCSVPage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // { path: '/', element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    // Auth
    {
      element: <Outlet />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <QRCodePage />
            </GuestGuard>
          ),
          index: true
        },
        {
          path: 'login-without-qr',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
          index: true
        },
        {
          path: 'get-started',
          element: (
            <AuthGuard>
              <GetStartedPage />
            </AuthGuard>
          ),
          index: true
        },
      ]
    },

    // Dashboard
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'user',
          children: [
            { element: <UserAccountPage />, index: true },
          ],
        },
        {
          path: 'overview',
          children: [
            { element: <OverviewPage />, index: true },
          ],
        },
        {
          path: 'a622aa7e-05b1-443f-95c7-6d6965426ed4/admin',
          children: [
            { element: <Navigate to="a622aa7e-05b1-443f-95c7-6d6965426ed4/admin/overview" replace />, index: true },
            { path: 'overview', element: <AdminOverviewPage /> },
            { path: 'invoices', element: <AdminInvoicesPage /> },
          ],
        },
        {
          path: 'payers',
          children: [
            { element: <MyPayers />, index: true },
            { path: ':id', element: <MyPayersDetails /> },
            { path: 'network/:id', element: <ExternalPayersDetails /> },
          ],
        },
        {
          path: 'recipients',
          children: [
            { element: <MyRecipient />, index: true },
            { path: ':id', element: <MyRecipientDetails /> },
            { path: 'mass-create', element: <MassRecipients /> },
          ],
        },
        {
          path: 'parties/add',
          children: [
            { path: ':partiesID', element: <Parties /> },
          ],
        },
        { path: 'inbox', element: <InvoiceInboxPage /> },
        {
          path: 'payments',
          children: [
            { element: <PaymentPage />, index: true  },
            { path: 'new', element: <PaymentNewInvoice /> },
            { path: 'mass-payout', element: <PaymentMassPayout /> },
            { path: 'transfer', element: <PaymentMassPayout /> },
            { path: ':id/edit', element: <PaymentEditInvoice /> },
            { path: ':id', element: <PaymentDetailsInvoice /> },
            { path: 'csv', element: <UploadCSVPage /> },
            { path: 'quick-payout', element: <PaymentQuickPayout /> },
          ],
        },
        {
          path: 'bills',
          children: [
            { element: <Navigate to="/bills/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
        { path: 'wallets', element: <Wallets /> },
        { path: 'billing', element: <Billing /> },

      ],
    },
    {
      path: "onboarding",
      element: (
        <AuthGuard>
          <OnboardingLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'become-payer',
          element: <BecomePayer />
        },
      ]
    },
    {
      element:
        <AuthGuard>
          <CompactLayout />
        </AuthGuard>,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '400', element: <Page400 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '423', element: <Page423 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
