import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";

// @mui
import { Box, Button, Dialog, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// types
import { IBalanceFB } from '../../../@types/wallet';
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { getListBalances } from "../../../redux/slices/balances";
// components
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { CurrencyIcon } from "../../../components/currencies";
import { useSnackbar } from '../../../components/snackbar';
import CopyText from '../../../components/text/CopyText';
// utils
import { fCurrency } from '../../../utils/formatNumber';
import uuidv4 from "../../../utils/uuidv4";
// hooks
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import FBWalletCard from '../../../sections/@dashboard/general/wallet/FBWalletCard';
import { totalSum } from '../../../utils/ArrayFormater';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

export default function BalancePopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [wallet, setWallet] = useState<IBalanceFB | null>(null);

  const dispatch = useDispatch();
  const { isLoading, balances } = useSelector((state) => state.balances);

  useEffect(() => {
    dispatch(getListBalances());
    // eslint-disable-next-line
  }, []);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(getListBalances());
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const filterBalance = balances?.find((item) => item?.env === 'Invoicer');

  const sum = filterBalance?.listVault ? totalSum(filterBalance?.listVault, 'available') : 0;

  const { enqueueSnackbar } = useSnackbar();

  const { copy } = useCopyToClipboard();

  const onCopy = (text: string) => {
    if (text) {
      enqueueSnackbar('Copied!');
      copy(text);
    }
  };

  return (
    <>
      <LoadingButton
        loading={isLoading}
        variant="text"
        size="large"
        onClick={handleOpenPopover}
        sx={{
          fontSize: 24,
          color: '#212B36',
          // border: `1px solid ${theme.palette.primary.main}`,
          mr: 4
        }}
      >
        {sum !== 0 ? fCurrency(sum ?? 0, '$') : '$0'}
      </LoadingButton>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 320 }}>
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Wallets <Typography
          component="span">({filterBalance?.listVault?.length ?? 1})</Typography>
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
          {filterBalance?.listVault?.map((item) => (
            <MenuItem
              key={uuidv4()} sx={{ height: ITEM_HEIGHT }}
            >
              <FBWalletCard
                wallet={item}
                handleWallet={() => setWallet(item)}
                handleCopy={() => onCopy(item.address)}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </MenuPopover>
      {wallet && (
        <Dialog
          fullWidth
          open={!!wallet}
          onClose={() => setWallet(null)}
          maxWidth="xs"
        >
          <Stack sx={{ p: 4 }} justifyContent="center" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <CurrencyIcon
                code={wallet.currency}
                chain={wallet.chain}
                style={{ width: "40px", height: "40px", margin: "auto", }}
              />
              <Typography variant="h5">
                {wallet.currency}
              </Typography>
            </Stack>
            <Box>
              <QRCode
                size={200}
                style={{ height: "auto", margin: "auto" }}
                value={wallet.address}
                viewBox={`0 0 256 256`}
              />
            </Box>
            <CopyText text={wallet.address} variant="subtitle1" start={30} end={30} size={20}/>
            <Button variant="outlined" color="inherit" onClick={() => setWallet(null)} sx={{ width: 166, height: 44 }}>
              Cancel
            </Button>
          </Stack>
        </Dialog>
      )}
    </>
  );
}
