import React from "react";
import { default as BinancecoinIcon } from "./BinancecoinIcon";
import { default as MaticCoinIcon } from "./MaticCoinIcon";
import { default as SnodeCoinIcon } from "./SnodeCoinIcon";
import { default as BitcoinCashIcon } from "./BitcoinCashIcon";
import { default as BitcoinIcon } from "./BitcoinIcon";
import { default as DashIcon } from "./DashIcon";
import { default as DogeIcon } from "./DogeIcon";
import { default as EtheriumClassicIcon } from "./EtheriumClassicIcon";
import { default as EtheriumIcon } from "./EtheriumIcon";
import { default as LitecoinIcon } from "./LitecoinIcon";
import { default as RippleIcon } from "./RippleIcon";
import { default as TronIcon } from "./TronIcon";
import { default as SolanaIcon } from "./SolanaIcon";
import { default as noImage } from "./NoImage";
import { CustomAvatar } from "../custom-avatar";
import TetherIcon from "./TetherIcon";
import USDCIcon from "./USDCIcon";
import { Stack } from "@mui/material";
import Label from "../label";
import CheckIcon from '../../assets/icons/CheckIcon';
import { useTheme } from '@mui/material/styles';
import { ExclamationMarkIcon } from '../../assets/icons';

const getIconById = (id: string) => {
  switch (id) {
    case "bitcoin":
      return BitcoinIcon;
    case "bitcoin-cash":
      return BitcoinCashIcon;
    case "litecoin":
      return LitecoinIcon;
    case "ethereum":
      return EtheriumIcon;
    case "ethereum-classic":
      return EtheriumClassicIcon;
    case "ripple":
      return RippleIcon;
    case "dash":
      return DashIcon;
    case "tron":
      return TronIcon;
    case "dogecoin":
      return DogeIcon;
    case "binancecoin":
      return BinancecoinIcon;
    case "matic-network":
      return MaticCoinIcon;
    case "snodecoin":
      return SnodeCoinIcon;
    case "solana":
      return SolanaIcon;
    case "usdt":
      return TetherIcon;
    case "usdc":
      return USDCIcon;
    default:
      return noImage;
  }
};

const getIconByCode = (code: string) => {
  switch (code) {
    case "BTC":
      return BitcoinIcon;
    case "BCH":
      return BitcoinCashIcon;
    case "LTC":
      return LitecoinIcon;
    case "ERC20":
    case "ETH":
      return EtheriumIcon;
    case "ETC":
      return EtheriumClassicIcon;
    case "XRP":
      return RippleIcon;
    case "DASH":
      return DashIcon;
    case "TRC20":
    case "TRX":
      return TronIcon;
    case "DOGE":
      return DogeIcon;
    case "BEP20":
    case "BNB":
      return BinancecoinIcon;
    case "POLYGON":
    case "ERC20_MATIC":
    case "MATIC":
      return MaticCoinIcon;
    case "SND":
      return SnodeCoinIcon;
    case "USDT":
      return TetherIcon;
    case "USDC":
      return USDCIcon;
    case "SPL":
    case "SOL":
      return SolanaIcon;
    default:
      return null;
  }
};

export const getLabel = (chain: string) => {
  switch (chain) {
    case 'TRX':
      return 'TRC20'
    case 'ETH':
      return 'ERC20'
    default:
      return chain;
  }
}

export const getColor = (chain: string) => {
  switch (chain) {
    case 'TRX':
    case 'TRC20':
      return 'error'
    case 'ETH':
    case 'ERC20':
      return 'info'
    default:
      return 'default';
  }
}

const CurrencyIcon = ({ id, code, chain, styleChain, approved, styleApproved = { display: 'none' }, ...props }: any) => {
  const theme = useTheme();
  let Icon;
  if (id) {
    Icon = getIconById(id);
  }

  if (code) {
    Icon = getIconByCode(code);
  }

  if (chain) {
    return <Stack position="relative" sx={{ textAlign: 'center' }}>
      {Icon ? <Icon {...props} /> :  <CustomAvatar {...props} name={code}/>}
      {code !== chain ? <Label position="absolute" color={getColor(chain)}  variant="filled" sx={{ bottom: 0, fontSize: 8, height: 15, ...styleChain }}>{getLabel(chain)}</Label> : ''}
      {approved ? (
        <CheckIcon
          width={20}
          color={theme.palette.success.main}
          style={{
            position: 'absolute',
            background: "#fff",
            borderRadius: '50%',
            border: '1px solid white',
            right: -10,
            bottom: -5,
            ...styleApproved,
          }}
        />
      ) : (
        <ExclamationMarkIcon
          width={20}
          color={theme.palette.error.main}
          style={{
            position: 'absolute',
            background: "#fff",
            borderRadius: '50%',
            border: '1px solid white',
            right: -10,
            bottom: -5,
            ...styleApproved,
          }}
        />
      )}
    </Stack>;
  }

  return Icon ? <Icon {...props} /> :  <CustomAvatar {...props} name={code}/>;
};

export default CurrencyIcon;
