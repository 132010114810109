import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
// @mui
import { Box, Link, Stack, Typography } from '@mui/material';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
// components
import { useAuthContext } from "../../auth/useAuthContext";
import LoadingScreen from "../../components/loading-screen";
import LoginLayout from '../../layouts/login';
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export default function QRCodePage() {
  const { getHashQR, loginQR } = useAuthContext();
  const navigate = useNavigate();
  const [hash, setHash] = useState<string | null>();

  const loadHash = () => {
    getHashQR().then((data) => {
      setHash(data);
    });
  }

  useEffect(() => {
    loadHash();
    // eslint-disable-next-line
  }, []);

  const get = () => {
    hash && loginQR(hash)
      .then(() => {
        navigate(PATH_DASHBOARD.root)
      })
  }

  useEffect(() => {
    get();

    const interval = setInterval(() => {
      get()
    }, 2000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [hash]);

  return (
    <>
      <Helmet>
        <title> QR Code | Invoicer Payperless</title>
      </Helmet>
      <LoginLayout>
        <Stack spacing={8} direction="column" alignItems="center" justifyContent="center">
          <Typography variant="h2" paragraph sx={{ color: '#444444' }}>
            <span style={{ color: '#30C979' }}>Sign in</span> to Invoicer
          </Typography>

          {!hash ? (
            <LoadingScreen/>
          ) : (
            <Box maxWidth={550}>
              <QRCode
                size={269}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`mobileauth:${hash}`}
                viewBox={`0 0 256 256`}
              />
            </Box>
          )}

          <Link
            to={PATH_AUTH.authWithoutQR}
            component={RouterLink}
            color="inherit"
            variant="subtitle2"
            sx={{
              mx: 'auto',
              alignItems: 'flex-end',
              display: 'inline-flex',
              fontSize: '24px',
              fontWeight: '400',

            }}
          >
            Sign-in options <Iconify icon="eva:chevron-right-fill" width={24} sx={{ mb: 0.45 }}/>
          </Link>
        </Stack>
      </LoginLayout>
    </>
  );
}
