// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST || '';
export const HOST_API_AUTH = process.env.REACT_APP_HOST_AUTH_API || '';
export const USER_API = `${process.env.REACT_APP_HOST}/${process.env.REACT_APP_USER_API}`;
export const WALLET_API = `${process.env.REACT_APP_HOST}/${process.env.REACT_APP_WALLET_API}`;
export const INVOICE_API = `${process.env.REACT_APP_HOST_INVOICE}`;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.bills.root; // as '/dashboard/app'

export const BLOCKCHAIN_NETWORK = process.env.BLOCKCHAIN_NETWORK || 'mainnet';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
