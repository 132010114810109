import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function ErrorIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#FF5631" fillOpacity="0.2" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FF5631" strokeOpacity="0.1" strokeWidth="8" />
        <path
          d="M27 31H29V33H27V31ZM27 23H29V29H27V23ZM28 18C22.47 18 18 22.5 18 28C18 30.6522 19.0536 33.1957 20.9289 35.0711C21.8575 35.9997 22.9599 36.7362 24.1732 37.2388C25.3864 37.7413 26.6868 38 28 38C30.6522 38 33.1957 36.9464 35.0711 35.0711C36.9464 33.1957 38 30.6522 38 28C38 26.6868 37.7413 25.3864 37.2388 24.1732C36.7362 22.9599 35.9997 21.8575 35.0711 20.9289C34.1425 20.0003 33.0401 19.2638 31.8268 18.7612C30.6136 18.2587 29.3132 18 28 18ZM28 36C25.8783 36 23.8434 35.1571 22.3431 33.6569C20.8429 32.1566 20 30.1217 20 28C20 25.8783 20.8429 23.8434 22.3431 22.3431C23.8434 20.8429 25.8783 20 28 20C30.1217 20 32.1566 20.8429 33.6569 22.3431C35.1571 23.8434 36 25.8783 36 28C36 30.1217 35.1571 32.1566 33.6569 33.6569C32.1566 35.1571 30.1217 36 28 36Z"
          fill="#FF5631" />
      </svg>
    </Box>
  );
}

export default memo(ErrorIcon);
