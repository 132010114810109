import React from "react";

import { SvgIcon } from '@mui/material';

const SolanaIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#7B64EC",
      ...props.style,
    }}
    viewBox="0 0 73 73"
  >
    <path d="M66.9997 36.5C66.9997 19.6553 53.3444 6 36.4997 6C19.655 6 5.99969 19.6553 5.99969 36.5C5.99969 53.3447 19.655 67 36.4997 67C53.3444 67 66.9997 53.3447 66.9997 36.5Z" fill="url(#paint0_linear_116_2)"/>
    <path d="M70 36.5C70 17.9985 55.0015 3 36.5 3C17.9985 3 3 17.9985 3 36.5C3 55.0015 17.9985 70 36.5 70C55.0015 70 70 55.0015 70 36.5Z" stroke="white" strokeWidth="6"/>
    <path d="M49.2517 42.3456L45.0612 46.6998C44.9705 46.7944 44.8608 46.8699 44.7387 46.9215C44.6166 46.9731 44.4849 46.9999 44.3517 47H24.4869C24.3921 47 24.2995 46.9731 24.2202 46.9228C24.141 46.8725 24.0786 46.8009 24.0407 46.7168C24.0028 46.6326 23.991 46.5396 24.0068 46.4491C24.0227 46.3585 24.0654 46.2744 24.1298 46.2071L28.3156 41.8529C28.4062 41.7584 28.516 41.6829 28.6381 41.6312C28.7601 41.5796 28.8919 41.5529 29.0251 41.5527H48.8899C48.9854 41.5508 49.0795 41.5764 49.1602 41.6261C49.2408 41.6759 49.3047 41.7477 49.3432 41.8324C49.382 41.9172 49.3938 42.0112 49.3775 42.1025C49.3612 42.1938 49.3174 42.2784 49.2517 42.3456ZM45.0612 33.5755C44.9702 33.4813 44.8603 33.4062 44.7384 33.3545C44.6164 33.3029 44.4848 33.276 44.3517 33.2753H24.4869C24.3921 33.2753 24.2995 33.3021 24.2202 33.3525C24.141 33.4028 24.0786 33.4744 24.0407 33.5585C24.0028 33.6427 23.991 33.7357 24.0068 33.8262C24.0227 33.9167 24.0654 34.0008 24.1298 34.0682L28.3156 38.4246C28.4066 38.5188 28.5164 38.5939 28.6384 38.6456C28.7604 38.6972 28.892 38.7242 29.0251 38.7248H48.8899C48.9845 38.7243 49.0767 38.6972 49.1557 38.6467C49.2347 38.5962 49.2966 38.5246 49.3342 38.4405C49.3718 38.3565 49.3834 38.2636 49.3673 38.1733C49.3515 38.083 49.3089 37.9991 49.2446 37.9319L45.0612 33.5755ZM24.4869 30.4474H44.3517C44.4849 30.4472 44.6166 30.4205 44.7387 30.3688C44.8608 30.3172 44.9705 30.2417 45.0612 30.1472L49.2517 25.793C49.3174 25.7258 49.3612 25.6411 49.3775 25.5498C49.3938 25.4585 49.382 25.3646 49.3432 25.2798C49.3047 25.195 49.2408 25.1233 49.1602 25.0735C49.0795 25.0237 48.9854 24.9982 48.8899 25.0001H29.0251C28.8919 25.0002 28.7601 25.027 28.6381 25.0786C28.516 25.1303 28.4062 25.2057 28.3156 25.3003L24.1298 29.6544C24.0654 29.7218 24.0227 29.8059 24.0068 29.8964C23.991 29.9869 24.0028 30.08 24.0407 30.1641C24.0786 30.2483 24.141 30.3199 24.2202 30.3702C24.2995 30.4205 24.3921 30.4473 24.4869 30.4474Z" fill="white"/>
    <defs>
      <linearGradient id="paint0_linear_116_2" x1="17.5" y1="6" x2="48.5" y2="67" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7B64EC"/>
        <stop offset="1" stopColor="#33D4B2"/>
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default SolanaIcon;
