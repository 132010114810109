import React from 'react';
// @mui
import { Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//
import { ICSVInvoice } from '../../../@types/invoice';
import { ExclamationMarkIcon } from '../../../assets/icons';
import uuidv4 from '../../../utils/uuidv4';
// ----------------------------------------------------------------------

type Props = {
  row: ICSVInvoice;
  index: number;
  wrongID: boolean;
  availableCurrencies: string[]
};

export default function CSVTableRow({ row, index, wrongID, availableCurrencies }: Props) {
  const {
    id,
    address,
    currency,
    amount,
    isAddressValid,
    isCurrencyValid,
    isTotalValid,
    recipient
  } = row;

  const hasAvailableCurrency = availableCurrencies?.includes(currency);

  const theme = useTheme();

  return (
    <TableRow id={uuidv4()} hover sx={{ cursor: 'pointer', background: index % 2 !== 0 ? theme.palette.background.neutral : '' }}>
      <TableCell>
        {index + 1}
      </TableCell>
      <TableCell>
        <Tooltip
          title={wrongID ? 'You have an old invoice with this number' : ''}
          arrow
          placement="left"
          color={wrongID ? theme.palette.error.main : ''}
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" noWrap>
              {id}
            </Typography>
            {wrongID && (
              <ExclamationMarkIcon
                width={20}
                color={theme.palette.error.main}
              />
            )}
          </Stack>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          id={uuidv4()}
          title={recipient ? '' : 'Recipient not found'}
          arrow
          placement="left"
          color={!recipient ? theme.palette.error.main : ''}
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" noWrap>
              {recipient ? recipient?.name : 'Recipient not found'}
            </Typography>
            {!recipient && (
              <ExclamationMarkIcon
                width={20}
                color={theme.palette.error.main}
              />
            )}
          </Stack>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          id={uuidv4()}
          title={isAddressValid ? (recipient ? (!recipient?.addresses?.approved ? 'Approval needed' : '') : '') : 'Invalid addresses'}
          arrow
          placement="left"
          color={isAddressValid ? (recipient ? (!recipient?.addresses?.approved ? theme.palette.error.main : '') : '') : theme.palette.error.main}
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" noWrap>
              {address}
            </Typography>
            {(isAddressValid ? (recipient ? (!recipient?.addresses?.approved) : false) : true) && (
              <ExclamationMarkIcon
                width={20}
                color={theme.palette.error.main}
              />
            )}
          </Stack>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={!isCurrencyValid
            ? "Invalid currency name"
            : !hasAvailableCurrency
              ? "You don't have this currency in your wallets"
              : ""
          }
          arrow
          placement="left"
          color={(!isCurrencyValid || !hasAvailableCurrency) ? theme.palette.error.main : ''}
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" noWrap>
              {currency}
            </Typography>
            {(!isCurrencyValid || !hasAvailableCurrency) && (
              <ExclamationMarkIcon
                width={20}
                color={theme.palette.error.main}
              />
            )}
          </Stack>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={isTotalValid ? '' : 'Invalid total number'}
          arrow
          placement="left"
          color={!isTotalValid ? theme.palette.error.main : ''}
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" noWrap>
              {amount}
            </Typography>
            {!isTotalValid && (
              <ExclamationMarkIcon
                width={20}
                color={theme.palette.error.main}
              />
            )}
          </Stack>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
