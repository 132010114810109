export const totalSum = (array: any[], name: string) => array
  ?.map(item => item[name])
  ?.reduce((prev, curr) => prev + curr, 0);

export const getTotal = (array: any[], name: string) => array
  ?.map(item => item[name])
  ?.reduce((prev, curr) => Number(prev) + Number(curr), 0);

export const getElements = (array: any[], code: string) => {
  const countItems = {}
  // eslint-disable-next-line
  array.map((item) => {
    // @ts-ignore
    countItems[item[code]] = countItems[item[code]] ? countItems[item[code]] + 1 : 1;
  });

  // @ts-ignore
  return Object.keys(countItems).filter((item) => countItems[item] >= 1)
}
