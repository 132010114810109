export const PLATFORM = ['ERC20', 'BEP20', 'ERC20_MATIC', 'TRC20', 'SPL']

export const currencyList = [
  // {
  //   id: 4,
  //   currency: 'TRX',
  //   chain: 'TRX',
  //   platform: ''
  // },
  {
    id: 5,
    currency: 'USDT',
    chain: 'TRX',
    platform: 'TRC20',
    currencyName: 'USDTTRC20'
  },
  {
    id: 6,
    currency: 'USDT',
    chain: 'ETH',
    platform: 'ERC20',
    currencyName: 'USDTERC20'
  },
  // {
  //   id: 7,
  //   currency: 'USDC',
  //   chain: 'TRX',
  //   platform: 'TRC20'
  // },
  {
    id: 8,
    currency: 'USDC',
    chain: 'ETH',
    platform: 'ERC20',
    currencyName: 'USDCERC20'
  }
];