import React from "react";

import { SvgIcon } from '@mui/material';

const TronIcon = (props: any) => (
  <SvgIcon
    {...props}
    style={{
      color: props.color || "#E50E16",
      ...props.style,
    }}
    viewBox="0 0 73 73"
  >
    <path
      d="M66.9996 36.5C66.9996 19.6553 53.3443 6 36.4996 6C19.6549 6 5.99963 19.6553 5.99963 36.5C5.99963 53.3447 19.6549 67 36.4996 67C53.3443 67 66.9996 53.3447 66.9996 36.5Z"
      fill="#E50E16"
    />
    <path
      d="M70 36.5C70 17.9985 55.0015 3 36.5 3C17.9985 3 3 17.9985 3 36.5C3 55.0015 17.9985 70 36.5 70C55.0015 70 70 55.0015 70 36.5Z"
      stroke="white"
      strokeWidth="6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5789 57.1917L23.7188 24.9405L36.1303 40.2461L34.5789 57.1917ZM36.5273 57.8461L38.1087 40.5739L51.2866 33.8879L36.5273 57.8461ZM41.4491 36.6364L52.156 31.2041L48.7298 28.488L41.4491 36.6364ZM23.6865 21.7253L46.7723 27.677L37.2002 38.3901L23.6865 21.7253Z"
      fill="white"
    />
  </SvgIcon>
);

export default TronIcon;
