
import React from 'react';
import { Typography } from '@mui/material';

export const CSV_PAYMENTS_INSTRUCTION = (
  <>
    <Typography variant="body2">Your file must contain these <b>4</b> column headers: <b>invoiceNumber</b>, <b>currency</b>, <b>address</b>, <b>amount</b>.</Typography>
    <Typography variant="body2">You can leave the 'invoiceNumber' column empty. In this case, invoice numbers will be generated.</Typography>
     {/*<Typography variant="body2">You can use 'csv', 'xls' or 'xlsx' files.</Typography> */}
  </>
);

export const CSV_RECIPIENTS_INSTRUCTION = (
  <>
    <Typography variant="body2">Your file must contain these <b>4</b> column headers: <b>name</b>, <b>currency</b>, <b>address</b>, <b>addressName</b>.</Typography>
    <Typography variant="body2">You can leave the 'invoiceNumber' column empty. In this case, invoice numbers will be generated.</Typography>
    {/*<Typography variant="body2">You can use 'csv', 'xls' or 'xlsx' files.</Typography>*/}
  </>
)
