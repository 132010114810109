import React from 'react';
// @mui
import { Stack, Typography } from '@mui/material';
// components
import { CurrencyIcon } from '../../../../components/currencies';
import { IconButtonAnimate } from '../../../../components/animate';
// utils
import { fCurrencyCrypto } from '../../../../utils/formatNumber';
// @types
import { IBalanceFB } from '../../../../@types/wallet';
import { CopyIcon, QRCodeIcon } from '../../../../assets/icons';

type IFBWalletProps = {
  wallet: IBalanceFB,
  handleCopy: VoidFunction;
  handleWallet: VoidFunction;
}

const FBWalletCard = ({ wallet, handleCopy, handleWallet }: IFBWalletProps) => {
  const startEnd = 10;

  return (
    <Stack direction="row" alignItems="center" spacing={1} width={1}>
      <CurrencyIcon
        code={wallet.currency}
        chain={wallet.chain}
        style={{ width: "40px", height: "40px", margin: "auto", }}
      />
      <Stack direction="column" width={1}>
        <Typography variant="subtitle1">
          {wallet?.available > 0 ? fCurrencyCrypto(wallet?.available, wallet?.currency) : `0 ${wallet?.currency}`}
        </Typography>
        <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="caption">
            {startEnd > 0 && wallet?.address ? wallet?.address.slice(0, startEnd) : ""}
            ...
            {startEnd > 0 && wallet?.address
              ? wallet?.address.slice(
                wallet?.address.length - startEnd,
                wallet?.address.length
              )
              : ""}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={0.5}>
          <IconButtonAnimate sx={{ width: 28 }} onClick={handleCopy} size="small">
            <CopyIcon width={16} style={{ margin: 0 }} />
          </IconButtonAnimate>
          <IconButtonAnimate sx={{ width: 28 }} onClick={handleWallet} size="small">
            <QRCodeIcon width={16} style={{ margin: 0 }} />
          </IconButtonAnimate>
      </Stack>
    </Stack>
  )
}


export default FBWalletCard;