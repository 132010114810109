import { useEffect, useState } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { IInvoice } from '../@types/invoice';
import { INVOICE_API } from '../config';
import { isValidToken } from '../auth/utils';
import { useSelector } from '../redux/store';
import { axiosInvocer } from '../utils/axios';
import uuidv4 from '../utils/uuidv4';

type TSseMessage = {
  id: string;
  event: 'invoice_status' | string,
  invoices?: IInvoice[],
  message?: string;
};

const useSse = () => {
  const token = sessionStorage.getItem('accessToken');

  const [ message, setMessage ] = useState<TSseMessage|null>(null);
  const { selected } = useSelector((state) => state?.invoices);

  const [refresh, setRefresh] = useState(0);

  const manualFetch = async () => {
    if(selected && selected?.length > 0) {
      await axiosInvocer.get('/invoices/list', {
        params: {
          invoiceIds: selected?.map(item => item.id)?.join(',') ?? []
        },
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      }).then((res) => {
        setMessage({
          id: uuidv4(),
          event: 'invoice_status_manualUpdate',
          invoices: res.data ? res.data : null,
          message: ''
        });
        setRefresh((prevState) =>  prevState + 1)
      })
    }
  }

  // const unsubscribe = async () => {
  //   await axiosInvocer.post('/invoices/unsubscribe', {}, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //     },
  //   })
  // }

  const subscribe = async (controller: AbortController) => {
    const { signal } = controller;

    await fetchEventSource(`${INVOICE_API}/invoices/subscribe`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      // openWhenHidden: true,

      onmessage(event: any) {
        // console.log('onmessage', Date.now());
        try {
          const parsedData = JSON.parse(event.data);
          setMessage({
            id: event.id,
            event: event.event,
            invoices: !parsedData?.message ? parsedData : null,
            message: parsedData?.message ?? ''
          });
        } catch(error) {
          console.error('useSse parsing error');
        }
      },

      onclose() {
        console.log('onclose', Date.now());
      //   unsubscribe();
      //   manualFetch();
      //   controller.abort();
      //   controller = new AbortController();
      },

      onerror() {
        // console.log('onerror', Date.now());
        // unsubscribe();
        manualFetch();
        controller.abort();
        controller = new AbortController();
      },
      signal
    });
  };

  useEffect(() => {
    let controller = new AbortController();

    if (isValidToken(token ?? '')) {
      subscribe(controller);
    }

    return () => {
      // unsubscribe();
      controller.abort();
    };
    // eslint-disable-next-line
  }, [token, selected?.length, refresh]);

  return message;
};

export default useSse;