// routes
// import { PATH_AUTH } from '../routes/paths';
// utils
import qs from "qs";
import axios from "axios";
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

export function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer, intervalTest: string | number | NodeJS.Timeout | undefined;

  const timeLeft = (exp * 1000 - Date.now());

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    if (isValidToken(sessionStorage.getItem('refreshToken') ?? '')) {
      try {
        clearInterval(intervalTest);
        // eslint-disable-next-line
        const { iss, azp } = jwtDecode(sessionStorage.getItem('refreshToken') ?? '');
        const { data: { access_token, refresh_token } } = await axios.post(`${iss}/protocol/openid-connect/token`, qs.stringify({
          refresh_token: sessionStorage.getItem('refreshToken'),
          grant_type: "refresh_token",
          client_id: azp ?? "account"
        })
        );
        setSession(access_token, refresh_token)
        // sessionStorage.setItem('refreshToken', refresh_token );
      } catch (e) {
        console.log('clearSession')
        clearSessions();
      }
    } else {
      console.log('clearSession')
      clearSessions();
    }

  }, timeLeft - 2000);
};

// ----------------------------------------------------------------------

export const clearSessions = () => {
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('refreshToken');

  window.location.href = PATH_AUTH.login;
}

export const setSession = (accessToken: string | null, refreshToken: string) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('refreshToken', refreshToken );

    const { exp } = jwtDecode(accessToken);

    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
  }
};

export const revalidateToken = () => {
  const accessToken = sessionStorage.getItem('accessToken') ?? '';
  const { exp } = jwtDecode(accessToken);
  tokenExpired(exp);
}