import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function PlusIcon({ ...other }: BoxProps) {
  return (
    <Box {...other} display="flex" justifyContent="cener" alignItems="center" width={other.width} height={other.width}>
      <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
                d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2" />
      </svg>
    </Box>
  );
}

export default memo(PlusIcon);
