// @mui
// components
import Logo from '../../components/logo';
//
import { StyledContent, StyledRoot, StyledSection, StyledSectionBg } from './styles';

// ----------------------------------------------------------------------

type Props = {
  leftElement: React.ReactNode;
  rightElement: React.ReactNode;
};

export default function GetStarted({ leftElement, rightElement }: Props) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />
      <StyledSection>
        {leftElement}
        <StyledSectionBg/>
      </StyledSection>
      <StyledContent>
        {rightElement}
      </StyledContent>
    </StyledRoot>
  );
}
