import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function ForbiddenIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other} sx={{ minWidth: { md: '464px' }, height: { lg: 'auto' } }}>
      <svg width="100%" height="100%" viewBox="0 0 464 309" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M94.1426 73.8603L86.018 82.8205C84.3429 84.6683 83.9339 87.3366 84.9786 89.6L157.657 253.261C158.632 255.457 161.068 256.611 163.387 255.977L176.36 252.431L94.1426 73.8603Z"
          fill="#49A365" />
        <path
          d="M237.233 213.552L179.147 251.249C174.671 254.153 168.657 252.381 166.476 247.515L92.7701 83.1094C90.5885 78.2434 93.2704 72.5818 98.42 71.1805L165.253 52.9935C167.196 52.4651 169.234 53.4284 170.056 55.2639L238.737 208.461C239.56 210.296 238.922 212.457 237.233 213.552Z"
          fill="#68CA87" />
        <path
          d="M233.208 210.565L177.568 245.884C175.581 247.145 172.936 246.351 171.973 244.205L98.9101 81.233C97.945 79.0808 99.1228 76.5756 101.396 75.9414L164.93 58.2287C166.771 57.7158 168.71 58.625 169.491 60.367L234.661 205.733C235.444 207.481 234.826 209.538 233.208 210.565Z"
          fill="#FFD55F" />
        <path
          d="M138.871 70.6529L124.566 74.8043C124.178 74.9171 123.773 74.6938 123.661 74.3076L123.294 73.0484C123.181 72.6614 123.405 72.2566 123.791 72.1446L138.097 67.9932C138.484 67.8804 138.889 68.1036 139.001 68.4899L139.368 69.7491C139.481 70.1353 139.258 70.5401 138.871 70.6529ZM144.384 69.0523L142.13 69.7066C141.503 69.8889 140.847 69.5281 140.664 68.9016L140.55 68.51C140.368 67.8835 140.729 67.2276 141.356 67.0453L143.61 66.391C144.237 66.2087 144.893 66.5694 145.076 67.1959L145.19 67.5876C145.372 68.2149 145.012 68.8707 144.384 69.0523Z"
          fill="#68CA87" />
        <path
          d="M180.956 163.052C188.39 159.446 189.827 147.081 184.166 135.434C178.504 123.787 167.888 117.268 160.454 120.874C153.019 124.48 151.582 136.845 157.243 148.492C162.905 160.139 173.521 166.658 180.956 163.052Z"
          fill="#49A365" />
        <path
          d="M182.758 162.177C190.192 158.571 191.63 146.206 185.968 134.559C180.307 122.912 169.69 116.393 162.256 119.999C154.822 123.605 153.384 135.97 159.046 147.617C164.707 159.264 175.324 165.783 182.758 162.177Z"
          fill="#68CA87" />
        <path
          d="M179.666 152.86L178.554 153.37C177.911 153.665 177.149 153.384 176.854 152.74L166.752 130.772C166.457 130.129 166.738 129.368 167.383 129.073L168.495 128.563C169.138 128.268 169.9 128.549 170.195 129.193L180.296 151.161C180.592 151.804 180.31 152.565 179.666 152.86Z"
          fill="#49A365" />
        <path
          d="M168.081 133.661L185.087 124.919V129.87L188.753 132.389L187.264 137.555L190.013 140.12L186.232 144.519L181.535 141.089L180.389 144.519L174.484 147.585L168.081 133.661Z"
          fill="#DEE2E6" />
        <path
          d="M185.087 124.919H186.29V129.585L190.013 131.931L188.639 137.367L191.559 139.884L190.013 140.12L187.264 137.555L188.753 132.389L185.087 129.87V124.919Z"
          fill="#979797" />
        <path d="M182.575 141.848L181.42 145.32L180.389 144.519L181.535 141.089L182.575 141.848Z" fill="#979797" />
        <path d="M172.726 131.272L171.51 137.367L176.359 146.612L174.484 147.585L168.081 133.661L172.726 131.272Z"
              fill="#979797" />
        <path d="M175.024 148.76L181.42 145.32L180.389 144.519L174.484 147.585L175.024 148.76Z" fill="#404040" />
        <path d="M186.232 144.519L187.435 144.777L191.559 139.884L190.013 140.12L186.232 144.519Z" fill="#404040" />
        <path d="M188.753 132.389L190.013 131.931L188.639 137.367L187.264 137.555L188.753 132.389Z" fill="#404040" />
        <path d="M185.087 129.87L186.29 129.585V124.919H185.087V129.87Z" fill="#404040" />
        <path
          d="M384.675 229.236L337.044 183.127L326.599 180.269L314.089 164.118L296.211 150.648L262.363 151.347L261.36 146.59L254.607 147.014L256.261 140.38L246.263 139.073L240.35 131.051L245.809 126.091L243.039 125.477L242.96 119.291L280.936 99.5783L320.401 95.2554L330.694 102.07L347.217 140.565L396.919 174.07L384.675 229.236Z"
          fill="#FFD55F" />
        <path
          d="M244.953 137.295L246.263 139.073L283.066 140.45L296.211 150.648L281.702 150.993L279.638 144.71L264.079 145.8L264.448 141.407L254.607 147.014L255.807 141.605L246.465 140.872L244.953 137.295Z"
          fill="#FF5631" />
        <path
          d="M259.142 121.121L281.236 123.595L300.455 118.295L305.014 107.572L300.144 114.235L296.667 107.537L295.887 113.776L278.5 113.554L259.142 121.121Z"
          fill="#FF5631" />
        <path d="M259.142 121.121L245.809 126.091L243.039 125.477L259.142 121.121Z" fill="#FF5631" />
        <path d="M245.809 126.091L258.697 124.288L260.139 133.135L246.263 139.073L240.35 131.051L245.809 126.091Z"
              fill="#FFAC5F" />
        <path d="M347.217 140.565L393.72 188.481L396.919 174.07L347.217 140.565Z" fill="#FF5631" />
        <path d="M343.549 186.397L337.649 182.521L344.058 179.091L341.863 182.763L343.549 186.397Z" fill="#FF5631" />
        <path d="M300.851 102.053L300.609 107.503L306.037 102.96L302.768 103.506L300.851 102.053Z" fill="#FFAC5F" />
        <path
          d="M270.582 122.402L263.907 111.163L249.3 106.528L238.301 121.121H211.836L217.335 126.091L215.616 128.558L211.836 130.618L216.991 131.68L214.93 134.94H221.116L224.037 130.618L225.756 134.94H235.551L235.895 131.207L242.104 133.43L240.35 131.051L245.809 126.091L260.139 121.121L270.582 122.402Z"
          fill="#DEE2E6" />
        <path d="M211.836 121.121L211.063 122.739L216.202 127.717L217.335 126.091L211.836 121.121Z" fill="#979797" />
        <path d="M211.836 130.618L211.114 132.198L216.014 133.226L216.991 131.68L211.836 130.618Z" fill="#979797" />
        <path d="M223.155 131.922L224.152 134.94H225.756L224.037 130.618L223.155 131.922Z" fill="#979797" />
        <path d="M235.762 132.651L243.332 135.096L242.104 133.43L235.895 131.207L235.762 132.651Z" fill="#979797" />
        <path d="M258.831 121.574L253.381 119.102L250.244 124.552L258.831 121.574Z" fill="#979797" />
        <path d="M214.929 134.94L214.414 134.395L215.28 133.072L216.991 131.68L214.929 134.94Z" fill="#404040" />
        <path d="M228.219 126.899L232.515 124.782L229.422 125.468L228.219 126.899Z" fill="#404040" />
        <path d="M228.219 126.899L231.828 125.84L232.515 124.782L228.219 126.899Z" fill="#979797" />
        <path d="M219.598 121.121L213.698 121.692L217.335 126.091L211.836 121.121H219.598Z" fill="#404040" />
        <path d="M249.299 106.528L246.378 106.957L235.762 121.121H238.301L249.299 106.528Z" fill="#979797" />
        <path
          d="M245.809 126.091L243.629 126.67L239.046 131.207L239.547 132.515L241.209 133.109L241.786 134.597L243.332 135.096L240.35 131.051L245.809 126.091Z"
          fill="#FF5631" />
        <path d="M204.704 164.134L207.625 156.58L212.437 159.155L211.063 162.932L204.704 164.134Z" fill="#DEE2E6" />
        <path d="M220.576 172.546L219.802 175.979L224.457 175.12L220.576 172.546Z" fill="#DEE2E6" />
        <path d="M191.559 158.125L188.896 163.447L196.97 162.245L191.559 158.125Z" fill="#DEE2E6" />
        <path d="M202.47 178.21L204.704 178.897L201.61 179.927L202.47 178.21Z" fill="#DEE2E6" />
        <path d="M201.61 153.752V150.057H203.902V151.43L201.61 153.752Z" fill="#DEE2E6" />
        <path d="M185.086 174.892L181.482 176.722H187.263L187.863 172.546L186.29 173.404L185.086 174.892Z"
              fill="#DEE2E6" />
        <path d="M191.559 158.125L193.677 158.583L199.205 162.789L196.97 162.245L191.559 158.125Z" fill="#979797" />
        <path d="M203.902 150.057L204.382 150.229V151.71L203.902 151.43V150.057Z" fill="#979797" />
        <path d="M212.437 159.155L213.039 159.799L211.521 163.934L211.063 162.932L212.437 159.155Z" fill="#979797" />
        <path d="M220.575 172.546L219.802 172.917L218.882 176.522L219.802 175.979L220.575 172.546Z" fill="#979797" />
        <path d="M202.47 178.21L201.75 178.844L200.955 180.475L201.61 179.927L202.47 178.21Z" fill="#979797" />
        <path d="M186.29 173.404L183.963 175.463L185.087 174.892L186.29 173.404Z" fill="#979797" />
        <path d="M181.482 176.723L182.009 177.47H187.755L187.263 176.723H181.482Z" fill="#979797" />
        <path d="M201.61 153.752L202.212 153.92L204.382 151.71L203.902 151.43L201.61 153.752Z" fill="#404040" />
        <path d="M188.896 163.447L191.471 163.934L199.205 162.789L196.97 162.245L188.896 163.447Z" fill="#404040" />
        <path d="M204.704 164.134L205.649 164.863L211.521 163.934L211.063 162.932L204.704 164.134Z" fill="#404040" />
        <path d="M224.457 175.12L224.066 175.737L218.882 176.522L219.802 175.979L224.457 175.12Z" fill="#404040" />
        <path d="M200.955 180.475L204.038 179.509L204.704 178.897L201.61 179.927L200.955 180.475Z" fill="#404040" />
        <path d="M187.863 172.546L188.432 173.361L187.755 177.47L187.263 176.722L187.863 172.546Z" fill="#404040" />
      </svg>
    </Box>
  );
}

export default memo(ForbiddenIllustration);
